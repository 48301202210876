import { useGenericMutation } from "@hooks/query"
import { useRuntimeEnv } from "@hooks/runtime-env"
import { useAjax } from "@hooks/useAjax"
import { useIPAddress } from "@hooks/utils"
import { AxiosResponse } from "axios"
import { useSaveLogs } from "../logs/useSaveLogs"
import { TAssociationCreate } from "./useAssociationCreateMutation"

export const useAssociationUpdateMutation = () => {
    const ajax = useAjax()
    const getIpAdress = useIPAddress()
    const saveLogs = useSaveLogs()
    const {runtimeEnv} = useRuntimeEnv()
    return useGenericMutation(async (data: TAssociationCreate) => {
        const ip = await getIpAdress()
        const response = await ajax.patch<TAssociationCreate, AxiosResponse<TAssociationUpdateResponse>>('backoffice/v1/update/association', {
            ...data,
            ip_address: ip
        })
        // await saveLogs({
        //     event_name: "edit",
        //     metadata: {
        //         thematic: "associations",
        //         thematicId: data.id,
        //         thematicName: data.first_name,
        //         author: runtimeEnv.email,
        //         ip_address: ip
        //     }
        // })
        return response.data
    })
}

export type TAssociationUpdateResponse = {
    message: string
}

