

import { SetUrlParamsCallback, useUrlParamsState } from "@hooks/url"
import { useCallback, useMemo } from "react"
import { SetterOrUpdater } from "recoil"
import { TListFilterState } from "../state/ListFilterState"
import { useListFilterGlobalState } from "./useListFilterGlobalState"



/**
 * This hooks let you get or set filter value of data grid
 * This hooks can handle "urlParamsState" and "memorizedState" just with the flag "isUrlParamsState"
 * 
 * @param id Id of the DataGrid component
 * @param isUrlParamsState flag telling if this hooks should use Url Params as filter state
 * @param defaultValue the initial value of state (It's not recommended to initialize it here, it's the DataGrid purpose)
 * @returns 
 */
export const useListFilterState = <TData extends {}>(id: string, isUrlParamsState: boolean = true, defaultValue?: TListFilterState<TData>) => {

    const { urlParams, setUrlParams } = useUrlParamsState<TListFilterState>(isUrlParamsState ? defaultValue : undefined)
    const { value: globalFilterValue, setValue: setGlobalFilterValue, resetValue: resetGlobalFilterValue } = useListFilterGlobalState(id, defaultValue)

    const filter = useMemo(() => {
        if (isUrlParamsState) {
            return urlParams
        }

        return globalFilterValue
    }, [globalFilterValue, urlParams])

    const setFilter = useCallback((key: string | SetUrlParamsCallback<TListFilterState> | SetterOrUpdater<TListFilterState>, value?: any) => {
        if (isUrlParamsState) {
            if (typeof key === 'string') {
                return setUrlParams(key, value)
            }
            return setUrlParams(key as SetUrlParamsCallback<TListFilterState>)
        }

        if (typeof key === 'string') {
            return setGlobalFilterValue(key, value)
        }

        return setGlobalFilterValue(key)
    }, [isUrlParamsState, setUrlParams, setGlobalFilterValue])

    return {
        filter,
        setFilter,
        resetGlobalFilterValue
    }
}
