import { MainLogo } from "@components/spinner/MainLogo"
import React, { Suspense } from "react"

export const GenericSuspenseWrapper = (props: GenericSuspenseWrapperProps) => {

    return (
        <Suspense fallback={props.fallback || <MainLogo />}>
            { props.children }
        </Suspense>
    )
}

type GenericSuspenseWrapperProps = {
    children: React.ReactNode
    fallback?: React.ReactNode
}
