import { AppRouteProps } from '@vadato/component'
import React from 'react'

const Email = React.lazy(() => import('./containers/Email'))

export const EmailRoutes: Array<AppRouteProps> = [
    { 
        path: 'email', 
        element: <Email />,
    }
]
