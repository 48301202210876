import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"

export const useAssociationByIdQuery = (associationId?: string, suspense?: boolean) => {
    const ajax = useAjax()
    return useGenericQuery<TAssociation>(['associations_query', associationId], async () => {
        const res = await ajax.get("/backoffice/v1/association?associationId=" + associationId)
        return res.data
    }, { suspense, enabled: associationId !== undefined })
}

export type TAssociation = {
    id: string
    first_name: string
    email: string
    color_code: string
    description: string
    logo: string
    payment_link: string
    verified_email: boolean,
    coinhouse_metadata: TAssociationCoinMetadata,
    min_amount: string
    max_amount?: string,
    email_notify_transaction?: string,
    last_status_change_at?: string,
    created_at: string
    is_active: boolean,
    status: number,
    ip_address: string
    bg_img: string
    title: string
    updtate_at: string
    expire_at?: string,
    deleted_at?: string,
    website?: string
}

export type TAssociationCoinMetadata = {
    id: string
    accountId: string
    name: string
    status: string
    codeProject: string
    typeProject: string
    apiKey: string
    hmacPassword: string
    autorizedByCNHS: boolean,
    parameters: {
        gasStation: any
    },
    dateCreated: string
    dateLastUpdated: string
}
