import { useCallback, useState } from "react"

export const useFormDataPartialEdit = ({ defaultEditedFields, onToggleEdit }: FormDataPartialEditProps) => {
    const [partialEdit, setPartialEdit] = useState<Array<string>>(defaultEditedFields || [])

    const isEditing = useCallback((name: string) => {
        return partialEdit.includes(name)
    }, [partialEdit])

    const toggleEditing = useCallback((name: string) => {

        let action = 'ADD'
        if (partialEdit.includes(name)) {
            action = 'REMOVE'
        }
        setPartialEdit((v) => {
            if (action === 'REMOVE') {
                return v.filter((item) => item !== name)
            }
            return ([...v, name])
        })
        onToggleEdit?.(name, action as any)
    }, [partialEdit, onToggleEdit])

    return {
        isEditing,
        toggleEditing
    }

}

export type FormDataPartialEditProps = {
    defaultEditedFields?: Array<string>
    onToggleEdit?: (name: string, action?: 'ADD' | 'REMOVE') => void
}
