import { useGenericQuery } from "@hooks/query";
import { useAjax } from "@hooks/useAjax";
import _ from "lodash";

export const useLogsQuery = (filter?: any, suspense?: boolean) => {
	const ajax = useAjax();
	const sortData = (sortKey: string, sortValue: string, data: any) => {
        const sortedData = data.sort(
            (itemPrev: any, itemNext: any) =>
                itemPrev[sortKey] - itemNext[sortKey]
            )
		return sortValue === "ASC"
			? sortedData
			: sortedData.reverse();
	};
	return useGenericQuery(
		["Vadato.Logs.List.Query", filter],
		async () => {
			try {
				const res = await ajax.get("backoffice/v1/read/log");
				const data =
					filter?.data?.thematics &&
					!_.isEmpty(filter?.data?.thematics)
						? res.data.filter((log: any) =>
								filter?.data?.thematics?.includes(log.metaData?.thematic)
						  )
						: res.data;
				return filter?.sort && !_.isEmpty(filter?.sort)
					? sortData(filter?.sort[0].value, filter?.sort[0].sort, data)
					: sortData("createdAt", "ASC", data);
			} catch (error) {
				return undefined;
			}
		},
		{ 
			suspense,
			enabled: !_.isNil(filter) && !_.isNil(filter.sort)
		}
	);
};
