import React, { CSSProperties } from "react"

export const ListBody = (props: ListBodyProps) => {
    
    return (
        <div 
            className={`border-default border-[1px] rounded-[30px] overflow-hidden list-body ${props?.listClassName}`}
            >
            {
                props?.rows?.map((item, key) => (
                    <div 
                        key={key}
                        className={`justify-between items-center px-[25px] py-[20px] border-b-[1px] gap-4 border-default grid`}
                        style={{ 
                            gridTemplateColumns: `repeat(${props.columnLength}, minmax(0, 1fr))`,
                            ...props.rowStyle
                        }}>
                        { props.onRenderRow?.(item, key) }
                    </div>
                ))
            }
        </div>
    )
}

export type ListBodyProps = {
    columnLength?: number
    rows: Array<any>
    listClassName?: string
    rowStyle?: CSSProperties
    onRenderRow?: (row: any, index?: number) => React.ReactNode
}
