import { useGenericMutation } from "@hooks/query";
import { useTranslations } from "@hooks/translation";
import { useAjax } from "@hooks/useAjax";

export const useOTPVerificationMutation = () => {
	const ajax = useAjax();
	const [
		SUCCESS_MESSAGE,
		ERROR_MESSAGE
	] = useTranslations(i18n)

	return useGenericMutation(
		(data: TVerifyOTP) => ajax.post("/auth/v1/verify/otp", data),
		{ success_message: SUCCESS_MESSAGE, error_message: ERROR_MESSAGE , onSuccess: (data, variables) => {
            localStorage.setItem('change_password_request_key', data.data?.change_password_request_key)
        } }
	);
};

const i18n = [
	"Vadato.Auth.ForgotPassword.VerificationSection.SuccessMessage",
	"Vadato.Auth.ForgotPassword.VerificationSection.ErrorMessage"
]

export type TVerifyOTP = {
	verify_key: string;
	otp: string;
};
