import axios from "axios";
import { atom, selector } from "recoil";

export const AjaxToken = atom({
    key: 'state.Ajax.token',
    default: localStorage.getItem("token")
})

export const Ajax = selector({
    key: "state.Ajax",
    get: ({ get }) => {
        const token = get(AjaxToken)
        const _axios = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers:{'Authorization': token, 'Content-Type': 'application/json'}
        });
        return _axios;
    }
})