import _ from "lodash"
import moment from "moment"
import React from "react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export const Timer = (props: TimerProps) => {
    const getDateFromNow = useCallback((value: string, format: string = 'x') => {
        const date_now = moment();
        const date_to_compare = moment(value, format);
        return {
            diff: date_to_compare.diff(date_now, 'seconds'),
            negative: date_now.isBefore(date_to_compare)
        }
    }, [])

    const getDiff = useCallback((value: string) => {
        const { diff: date_span } = getDateFromNow(value)
        return moment.duration((date_span + 1), 'seconds')
    }, [])

    const [duration, setDuration] = useState<moment.Duration>(getDiff(props.value))
    const _interval = useRef<NodeJS.Timeout>()

    useEffect(() => {
        return () => {
            clearTimeout(_interval.current!)
        }
    }, [])

    useEffect(() => {
        if (!props.disabled) {
            clearInterval(_interval.current!)
            beginAnimation()
        }
    }, [props.value, props.disabled])


    const beginAnimation = () => {
        _interval.current = setInterval(() => {
            setDuration(getDiff(props.value))
        }, props.interval || 1000)
    }

    const isNegative = useMemo(() => {
        const { negative } = getDateFromNow(props.value)
        return negative
    }, [props.value, duration])

    return (
        <span className={isNegative ? 'negative' : ''}>
            {
                props.onRenderDuration?.(duration)
            }
            {
                !props.onRenderDuration &&
                <>
                    {duration?.hours() || '-'} : {duration?.minutes() || '-'} : {duration?.seconds() || '00'}
                </>
            }
        </span>
    )
}

export type TimerProps = {
    disabled?: boolean
    interval?: number
    value: string
    onRenderDuration?: (duration: moment.Duration) => React.ReactNode
}
