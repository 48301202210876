import { useEffect } from "react"

export const Logout = () => {

    useEffect(() => {
        localStorage.removeItem('token')
        window.open('/auth/login', '_self')
    }, [])

    return (
        <></>
    )
}
