import { useTranslation } from "@vadato/component"

export const BlockMobileMessage = () => {

    const t = useTranslation()

    return (
        <div className="w-screen h-screen flex justify-center items-center">
            <div className="flex flex-col items-center gap-2">
                <img src={`/logo.PNG`} className="w-60" />
                <p className="text-center">
                    {t('Vadato.Screen.Small.Forbidden.Message')}
                </p>
            </div>
        </div>
    )
}
