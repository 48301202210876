import { useGenericMutation } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import { useAjaxToken } from "@hooks/useAjaxToken"
import { useIPAddress } from "@hooks/utils"
import { useSaveLogs } from "../logs"

export const useLoginMutation = () => {
    const ajax = useAjax()
    const [, setAjaxToken] = useAjaxToken()
    const saveLogs = useSaveLogs()
    const getIpAdress = useIPAddress()
    return useGenericMutation(async (data: TLogin) => {
        const res = await ajax.post('auth/v1/login', data)
        setAjaxToken(res.data.access_token)
        // const ip = await getIpAdress()
        // await saveLogs({
        //     event_name: "login",
        //     metadata: {
        //         thematic: "auth",
        //         thematicName: data.username,
        //         author: data.username,
        //         ip_address: ip
        //     }
        // }, {
        //     'Authorization': res.data.access_token
        // })
    })
}

export type TLogin = {
    username: string
    password: string
}
