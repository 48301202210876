import { useCallback } from "react"
import { useTranslationLang } from "./useTranslationLang"

export const useTranslationRoute = () => {

    const { lang } = useTranslationLang()

    return useCallback((route: string) => {
        return `/${route.replace(/^\//, '')}`
    }, [lang])
}
