import { AjaxToken } from "@state/Ajax"
import { useCallback } from "react"
import { useRecoilState } from "recoil"

export const useAjaxToken = (): [string | null, (value: string) => void] => {
    const [token, setToken] = useRecoilState(AjaxToken)

    const setAjaxToken = useCallback((value: string) => {
        setToken(value)
        localStorage.setItem('token', value)
    }, [setToken])

    return [token, setAjaxToken]
}
