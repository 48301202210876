import { useGenericMutation } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"

export const useFileUpload = () => {
    const ajax = useAjax()
    return useGenericMutation(async (file: File) => {
        const response = await ajax.get<TFileRequestResponse>('backoffice/v1/upload/request')
        const fileS3UploadUrl = response.data.uploadURL
        const fileUrl = response.data.uploadURL.split('?').shift()
        const blob = await new Promise<Blob>((resolve) => {
            const reader = new FileReader();
            reader.onload = function (e: any) {
                const blob = new Blob([new Uint8Array(e.target.result)], { type: file.type });
                resolve(blob)
            };
            reader.readAsArrayBuffer(file);
        })
        await ajax.put(fileS3UploadUrl!, blob, {
            headers: { 
                "content-type": blob.type,
                "Authorization": undefined
            }
        })
        return fileUrl
    })
}

export type TFileRequestResponse = {
    uploadURL: string
    Key: string
}

