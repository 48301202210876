import { TDonatorCreate, useDonatorCreateMutation } from "@hooks/data"
import { useMetaMask } from "metamask-react/lib/use-metamask"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useCurrentPayment } from "./useCurrentPayment"
import { useMetamaskMutation } from "./useMetamaskMutation"

export const useAssociationDonationSubmit = () => {


    const { currentPayment, isExpired, setPayment, paymentResult, paymentResultLoading } = useCurrentPayment()
    const creationMutation = useDonatorCreateMutation()
    const { account } = useMetaMask();
    const [showDialog, setShowDialog] = useState(currentPayment !== undefined)

    const metamaskMutation = useMetamaskMutation()

    useEffect(() => {
        if (paymentResult?.message !== 'wait') {
            setShowDialog(false)
        }
    }, [paymentResult?.message])

    useEffect(() => {
        if (metamaskMutation.isSuccess) {
            metamaskMutation.reset()
        }
    }, [metamaskMutation.isSuccess])

    const submit = useCallback((formData: Partial<TDonatorCreate>, callback?: any) => {
        creationMutation.mutate({
            ...formData,
            phone: formData.phone?.replace(/\s/g, ''),
            dob: moment(formData.dob).format('MM/DD/YYYY'),
            amount: parseFloat(formData.amount?.toString()!)
        }, {
            onSuccess: async (data) => {
                setPayment(data)
                callback?.()
                if (account) {
                    metamaskMutation.mutate(data)
                    return;
                }
                setShowDialog(true)
            }
        })
    }, [account])


    const showPatiente = useMemo(() => {
        if (metamaskMutation.isLoading || metamaskMutation.isError || metamaskMutation.isSuccess) {
            return true
        }
        return creationMutation.isError || currentPayment || showDialog
    }, [creationMutation, currentPayment, showDialog, metamaskMutation])

    const isError = useMemo(() => {
        if (paymentResult?.message === 'error') {
            return true
        }
        if (metamaskMutation.isError) {
            return true
        }
        return creationMutation.isError || isExpired()
    }, [metamaskMutation, creationMutation, isExpired, paymentResult])

    const isSuccess = useMemo(() => {
        if (paymentResult?.message === 'success') {
            return true;
        }

        if (metamaskMutation.isError) {
            return false
        }

        return !isExpired()
    }, [metamaskMutation, isExpired, paymentResult])
    
    const isLoading = useMemo(() => {
        if (isError) {
            return false
        }
        if (paymentResultLoading || paymentResult?.message === 'wait') {
            return true
        }
        if (metamaskMutation.isLoading || metamaskMutation.isSuccess) {
            return true
        }

        if (metamaskMutation.isError) {
            return false
        }

        return !isExpired()
    }, [metamaskMutation, isExpired, paymentResult, paymentResultLoading, isError, isSuccess])

    return {
        metamaskMutation,
        creationMutation,
        currentPayment,
        paymentResult,
        showDialog,
        setShowDialog,
        showPatiente,
        isError,
        isSuccess,
        isLoading,
        isExpired,
        account,
        submit
    }
}

