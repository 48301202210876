import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useEmailQuery = (filter?: any, suspense?: boolean) => {
    const ajax = useAjax()
    return useGenericQuery(['emails_query', filter] ,async () => {
        try {
            const res = await ajax.get(`/backoffice/v1/email/template/list`)
            return res
        } catch (error) {
            return []
        }
    }, { suspense })
}

