import _ from "lodash"
import { SetterOrUpdater, useRecoilState, useResetRecoilState } from "recoil"
import { ListFilterState, TListFilterState } from "../state/ListFilterState"

export const useListFilterGlobalState = (id: string, defaultValue?: TListFilterState) => {

    const [value, setValue] = useRecoilState(ListFilterState(id, defaultValue))
    const resetValue = useResetRecoilState(ListFilterState(id));

    const setFilterValue = (key: string | SetterOrUpdater<TListFilterState>, value?: any) => {
        if (typeof key === 'string') {
            return setValue((v: TListFilterState) => ({ ...v, [key]: value }))
        }
        setValue(key)
    }

    return {
        value,
        setValue: setFilterValue,
        resetValue
    }
}
