import React from "react"

export const BaseControlError = (props: BaseControlErrorProps) => {

    return (
        <div className="flex items-center gap-2 ">
            <img
                src="/assets/img/icons/error.svg"
                loading="lazy"
                width={20}
                className=""
                alt=""
            />
            <span data-testid={`control-error-${props.name}`} className="text-danger text-message">{props.error}</span>
        </div>
    )
}

export type BaseControlErrorProps = {
    name?: string
    error?: string
}
