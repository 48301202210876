import { AppRouteProps } from "@vadato/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import AuthLayout from "components/layout/AuthLayout";
import MainLayout from "components/layout/MainLayout";
import { Outlet } from "react-router-dom";
import { AssociationRoutes } from "./associations/AssociationRoutes";
import { AuthRoutes } from "./auth/AuthRoutes";
import { AssociationCreateRoutes } from "./association-create/AssociationCreateRoutes";
import { DonationsRoutes } from "./donations/DonationsRoutes";
import { DonatorsRoutes } from "./donators/DonatorsRoutes";
import { LogsRoutes } from "./logs/LogsRoutes";
import { EmailRoutes } from "./emails/EmailRoutes";

export const VadatoRoutes: Array<AppRouteProps> = [
    {
        path: 'auth',
        title: `Vadato.Title`,
        element: <AuthLayout />,
        children: [
            ...AuthRoutes
        ]
    },
    {
        path: '',
        title: `Vadato.Title`,
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
            ...AssociationRoutes,
            ...AssociationCreateRoutes,
            ...DonationsRoutes,
            ...DonatorsRoutes,
            ...LogsRoutes,
            ...EmailRoutes
        ]
    },
]