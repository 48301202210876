
import { GenericSuspense } from "@vadato/component";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {

    return (
        <div className="auth-layout">
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </div>
    )
}

export default AuthLayout;
