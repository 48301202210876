import React, { CSSProperties, useCallback } from "react"
import { ListHeaderItem, ListHeaderItemProps } from "./ListHeaderItem"

export const ListHeader = (props: ListHeaderProps) => {

    const getValue = useCallback((value?: string) => {
        return props.value?.find((item) => item.value === value)
    }, [props.value])

    return (
        <div
            className={`w-full grid gap-2 list-header`}
            style={{ 
                gridTemplateColumns: `repeat(${props.columns.length}, minmax(0, 1fr))`,
                ...props.headerStyle
            }}>
            {
                props.columns.map((item, index) => {
                    const _val = getValue(item.value)
                    let nextValue: SortEnum | null = null
                    if (!_val) {
                        nextValue = 'ASC'
                    }
                    if (_val?.sort === 'ASC') {
                        nextValue = 'DESC'
                    }
                    return (
                        <ListHeaderItem
                            key={index}
                            {...item}
                            sort={_val?.sort}
                            onClick={item.sortable ? () => props.onChange?.(item.value, nextValue) : undefined}
                        />
                    )
                })
            }
        </div>
    )
}

export type ListHeaderProps = {
    columns: Array<ListHeaderItemProps>
    headerStyle?: CSSProperties
    value?: Array<SortValue>
    onChange?: (value: string, sort: SortEnum | null) => void
}

export type SortValue = {
    value: string
    sort: SortEnum
}

export type SortEnum = 'ASC' | 'DESC'
