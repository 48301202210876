import { Dropdown } from "@components/dropdown"
import { useTranslation } from "@hooks/translation"
import { useCryptoConvertion } from "@hooks/utils"
import React, { createRef, HTMLAttributes, useCallback, useEffect, useState } from "react"
import { IconButton } from ".."
import { BaseControl, BaseControlProps } from "./BaseControl"
import { BaseControlError } from "./BaseControlError"
import { CryptoList } from "./components/CryptoList"
import { CryptoPad } from "./CryptoPad"

export const CryptoCurrency = ({ readonly, value, ...props }: CryptoCurrencyProps) => {

    const [currencyButtonRef, setCurrencyButtonRef] = useState<HTMLDivElement>()
    const [cryptoPadContainerRef, setCryptoPadContainerRef] = useState<HTMLDivElement>()
    const containerRef = createRef<HTMLDivElement>()
    const t = useTranslation()
    const { convertToEuro } = useCryptoConvertion()

    useEffect(() => {
        // Exception to add ETH by default
        if (!value?.currency) {
            props.onChange?.({ ...value, currency: 'ETH' })
        }
    }, [])

    const handlePadClick = useCallback((val: string) => {

        if (val === '*') {
            setCryptoPadContainerRef(undefined)
            props.onBlur?.({} as any)
            return;
        }

        let nextVal = value?.value || ''
        if (val === '\b') {
            nextVal = nextVal.substring(0, nextVal.length - 1)
        } else {
            nextVal += val
        }
        props.onChange?.({ ...value, value: nextVal })

    }, [value])

    return (
        <BaseControl {...props} error={undefined}>
            <div className="flex flex-col gap-2">
                <div
                    className="flex gap-2 h-[80px]"
                    ref={containerRef}>
                    <div
                        className="flex flex-col justify-center gap-3 px-2 bg-white form-group rounded-[10px]"
                        onClick={(e) => {
                            // setCurrencyButtonRef(containerRef.current!)
                        }}>
                        <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-up.svg" className="h-4" />} />
                        <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-down.svg" className="h-4" />} />
                    </div>
                    <div
                        data-cy="home-page-donator-crypto-input"
                        className="flex flex-1 gap-6 items-center py-5 px-4 bg-white w-full rounded-[10px] cursor-pointer form-group"
                        onClick={(e) => {
                            if (!value?.currency) {
                                setCurrencyButtonRef(containerRef.current!)
                                return;
                            }
                            setCryptoPadContainerRef(containerRef.current!)
                        }}>
                        <div className="w-9">
                            {
                                value?.currency &&
                                <img src={`/assets/img/icons/${value?.currency}-circle.svg`} className="w-full" />
                            }
                        </div>
                        <h2 className="text-primary">
                            {value?.value}
                        </h2>
                    </div>
                </div>
                {
                    !value?.currency &&
                    <p>{t('Vadato.Donators.Create.Form.SelectCrypto.Label')}</p>
                }
                {
                    props.error &&
                    <BaseControlError error={props.error} />
                }
                {
                    value?.currency &&
                    <p className="text-right">{value.value} {value.currency} = {convertToEuro(parseFloat(value.value || '0'), value.currency)} €</p>
                }
            </div>

            <Dropdown anchorEl={currencyButtonRef} onClose={() => setCurrencyButtonRef(undefined)}>
                <CryptoList value={value?.currency} onClick={(val) => {
                    props.onChange?.({ ...value, currency: val })
                    setCurrencyButtonRef(undefined)
                    setCryptoPadContainerRef(containerRef.current!)
                }} />
            </Dropdown>

            <Dropdown anchorEl={cryptoPadContainerRef} onClose={() => {
                props.onBlur?.({} as any)
                setCryptoPadContainerRef(undefined)
            }}>
                <CryptoPad onClick={handlePadClick} />
            </Dropdown>
        </BaseControl>
    )
}

CryptoCurrency.defaultValue = {
    value: {}
}

export type CryptoCurrencyProps = BaseControlProps & Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    value?: CryptoCurrencyValue
    onChange?: (value?: CryptoCurrencyProps['value']) => void
}

export type CryptoCurrencyValue = { currency?: string, value?: string }
