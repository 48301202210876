import { useCallback } from 'react';

export const useUrlValidation = () => {

    return useCallback((url: string): boolean => {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    }, [])

}