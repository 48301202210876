import { useFileUpload } from "@hooks/data/file/useFileUpload";
import _ from "lodash";
import React, { HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { IconButton } from "..";
import { BaseControl, BaseControlProps } from "./BaseControl";
import { BounceLoader } from "react-spinners";
import { ImageCropperDialog } from "./components/ImageCropperDialog";
import { useReadFileToBase64 } from "./hooks/useReadFileToBase64";
import { useConvertBase64ToFile } from "./hooks/useConvertBase64ToFile";
import { FileDrop } from 'react-file-drop';
import { useReadImageFile } from "./hooks/useReadImageFile";
import { useDidMountEffect } from "@hooks/utils";
import { useAjaxToken } from "@hooks/useAjaxToken";

export const ImageControl = ({
	readonly,
	multiple,
	value,
	filesType,
	minimunSize,
	maximumWeight,
	...props
}: ImageControlProps) => {

	const mutation = useFileUpload()
	const [token] = useAjaxToken()
	const [openDialog, setOpenDialog] = useState(false)
	const [image, setImage] = useState<string>()
	const [validationError, setValidationError] = useState('')
	const readFile = useReadFileToBase64()
	const convertToFile = useConvertBase64ToFile()
	const readImage = useReadImageFile()

	useEffect(() => {
		if (mutation.isSuccess) {
			props.onChange?.(mutation.data)
			mutation.reset()
		}
	}, [mutation])

	useEffect(() => {
		if (value && !image) {
			loadDefaultImage()
		}
	}, [value])

	const loadDefaultImage = useCallback(async () => {
		try {
			const res = await fetch(`${process.env.REACT_APP_API_URL}/v1/image/download?url=${encodeURIComponent(value as string)}`, {
				headers: {
					'Authorization': token as string
				}
			})
			const img = await res.text()
			setImage(img)
		} catch (e) {
			console.warn('Failed to load default image', e)
		}
	}, [value])

	const handleCropperChange = useCallback(async (value: string) => {
		const file = await convertToFile(value)
		mutation.mutate(file)
		setOpenDialog(false)
	}, [mutation])

	const validateFile = useCallback(async (file: File) => {
		let validation = ''
		if (!/(.jpg|.jpeg|.png|.svg)$/.test(file.name.toLocaleLowerCase())) {
			validation = 'Type du fichier : png, svg, jpeg'
		}

		if (file.size > 3_000_000) {
			validation = 'La taille du fichier doit être en dessous de 3Mo'
		}

		const image = await readImage(file)
		if (image.width < minimunSize?.width! || image.height < minimunSize?.height!) {
			validation = `La résolution minimum est de ${minimunSize?.width}x${minimunSize?.height}`
		}

		setValidationError(validation)
		return validation
	}, [])

	const handleOnChange = React.useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;

		if (!files) {
			return;
		}

		if (_.isEmpty(files)) {
			return;
		}

		if (await validateFile(files[0]) !== '') {
			return;
		}

		setImage(await readFile(files[0]))
		setOpenDialog(true)
	}, [multiple])

	const handleDropFile = async (files: FileList | null) => {
		if (!files?.[0]) {
			return;
		}
		const file = files[0]
		if (await validateFile(file)) {
			return;
		}
		setImage(await readFile(file))
		setOpenDialog(true)
	}

	const showInput = useMemo(() => {
		if (props.partialEdit) {
			return props.partialEditing && !value
		}

		if (!value) {
			return true
		}

		return false

	}, [props, value])

	const handleImgClick = useCallback(() => {
		if (props.partialEdit) {
			props.partialEditing && setOpenDialog(true)
			return;
		}
		
		setOpenDialog(true)
	}, [props, setOpenDialog])

	return (
		<BaseControl {...props} error={validationError || props.error}>
			<div className="flex flex-col gap-4">
				<FileDrop
					onDrop={handleDropFile}
				>
					<div className="flex items-end gap-7 ">
						<label
							className="w-[156px] h-[114px] relative cursor-pointer flex justify-center rounded-md border-dashed border-[1px] border-black p-2"
							htmlFor={!readonly ? props.name : undefined}>
							{
								value &&
								<div className="flex flex-col gap-1 w-full">
									<div className="flex-1 flex justify-end mt-[-10px]">
										<IconButton
											icon={<img src="/assets/img/icons/error.svg" className="w-4" />}
											onClick={() => props.onChange?.('')}
										/>
									</div>
									<img
										src={value as string}
										className="object-contain h-[60px] "
										onClick={handleImgClick}
									/>
								</div>

							}
							{
								mutation.isLoading &&
								<div className="m-auto text-center px-4 absolute inset-0 flex justify-center items-center">
									<BounceLoader size={30} />
								</div>
							}
							{
								(!value && !mutation.isLoading) &&
								<div className="m-auto text-center px-4">{props.placeholder}</div>
							}

							{
								(showInput) &&
								<input
									type="file"
									{...props}
									id={props.name}
									multiple={multiple}
									accept="image/*"
									style={{ display: "none" }}
									onChange={handleOnChange}
								/>
							}
						</label>
						<div className="flex flex-col justify-end gap-1">
							<div className="flex items-start gap-1 italic text-sm">
								<span>type du fichier :</span>
								<span>{filesType}</span>
							</div>
							<div className="flex items-start gap-1 italic text-sm">
								<span>taille minimum :</span>
								<span>{`${minimunSize?.width} x ${minimunSize?.height}`}</span>
							</div>
							<div className="flex items-start gap-1 italic text-sm">
								<span>taille maximum :</span>
								<span>{`${maximumWeight}`}</span>
							</div>
						</div>
					</div>
				</FileDrop>
			</div>

			<ImageCropperDialog
				data-cy={props["data-cy"]}
				open={openDialog}
				value={image}
				cropSize={props.cropSize}
				onClose={() => setOpenDialog(false)}
				onCancel={() => setOpenDialog(false)}
				onChange={handleCropperChange}
			/>
		</BaseControl>
	);
};

export type ImageControlProps = BaseControlProps &
	Omit<HTMLAttributes<HTMLInputElement>, "onChange"> & {
		onChange?: (value: ImageControlProps["value"]) => void;
		multiple?: boolean;
		value?: string | Array<string>;
		filesType?: string;
		minimunSize?: Size;
		maximumWeight?: string;
		cropSize?: { width: number, height: number }
		"data-cy"?: string
	};

export type Size = {
	width: number;
	height: number;
};
