import React, { createRef, ReactNode, useState } from 'react'
import { useDebounce } from '../../hooks'
import { Dropdown, DropdownProps } from '../dropdown'
import './css/style.css'

export const Tooltip = (props: TooltipProps) => {

    const containerRef = createRef<HTMLDivElement>()
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()
    const debounce = useDebounce()

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.trigger === 'click') {
            setAnchorEl(e.currentTarget)
        }
    }

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.trigger === 'hover') {
            setAnchorEl(e.currentTarget)
        }
    }

    const handleMouseLeave = () => {
        if (props.trigger === 'hover') {
            handleClose()
        }
    }

    const handleClose = () => {
        if (props.closeTimeout === 0) {
            setAnchorEl(undefined)
            return;
        }
        debounce(() => setAnchorEl(undefined), props.closeTimeout || 750)
    }

    return (
        <>
            <div
                onClick={handleClick}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={containerRef}>
                {props.children}
            </div>
            <Dropdown placement={props.placement} anchorEl={anchorEl} onClose={handleClose}>
                <div className='tooltip-container'>
                    { props.text }
                </div>
            </Dropdown>
        </>
    )
}

Tooltip.defaultProps = {
    trigger: 'click',
    closeTimeout: 750
}

export type TooltipProps = {
    /** Text to show inside tooltip */
    text?: string | ReactNode
    /** Way to trigger tooltip */
    trigger?: 'click' | 'hover'
    closeTimeout?: number
    children?: ReactNode
    placement?: DropdownProps['placement']
}
