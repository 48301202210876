import { useDebounce } from "@hooks/utils/useDebounce";
import _ from "lodash";
import React, { ReactNode, useCallback, useMemo } from "react";
import { useFormDataAlphaValidation } from "./useFormDataAlphaValidation";
import { useFormDataError } from "./useFormDataError";
import { useFormDataPartialEdit } from "./useFormDataPartialEdit";
import { useGlobalFormData } from "./useGlobalFormData";
import { useGlobalFormDataError } from "./useGlobalFormDataError";
import { useGlobalFormDataErrorReset } from "./useGlobalFormDataErrorReset";
import { useGlobalFormDataReset } from "./useGlobalFormDataReset";
import {useFormDataAlphaNumericValidation} from './useFormDataAlphaNumericValidation'
import { useFormDataInputProps } from "./useFormDataInputProps";

export const useFormData = <TData extends {} = any>(props: Props<TData>) => {

    const globalStateId = useMemo(() => props.id || _.uniqueId(), [props.id])
    const [formData, setFormData] = useGlobalFormData(globalStateId, props.formData);
    const [errors, setErrors] = useGlobalFormDataError(globalStateId)
    const resetFormData = useGlobalFormDataReset(globalStateId)
    const resetErrors = useGlobalFormDataErrorReset(globalStateId)
    const formDataError = useFormDataError(globalStateId)
    const evalAlphaValidation = useFormDataAlphaValidation()
    const evalAlphaNumericValidation = useFormDataAlphaNumericValidation()

    /**
     * It will scroll into the invalid element that has the same "name" or "id" attribute 
     */
    const isValid = (render: boolean = true): boolean => {
        const _errors = evalErrors(render === false)
        if (render) {
            setErrors(_errors)
            // scroll to invalid element
            Object.keys(_errors).forEach((key) => {
                const element = document.getElementById(key) || document.querySelector(`[name=${key.replaceAll('.', '_')}]`)
                element && debounce(() => {
                    element.scrollIntoView?.({ behavior: 'smooth', block: "center", inline: "nearest" })
                }, 100)
            })
        }
        return _.isEmpty(_.omitBy(_errors, _.isNil))
    }
    
    const handleEditToggle = useCallback((name: string, action?: string) => {
        if (action === 'REMOVE') {
            isValid()
        }

        if (action === 'ADD') {
            document.getElementById(name)?.focus()
            document.querySelector<HTMLInputElement>(`[name="${name.replaceAll('.', '_')}"]`)?.focus()
        }
    }, [isValid])

    const { isEditing, toggleEditing } = useFormDataPartialEdit({
        defaultEditedFields: props.defaultEditedFields,
        onToggleEdit: handleEditToggle
    })

    const handleInputChange = (key: string, value: any) => {

        if (props.readonly) {
            return;
        }
        
        if (props.partialEdit && !isEditing(key)) {
            return;
        }

        setFormData((v) => {
            const newVal = _.cloneDeep(v)
            _.update(newVal, key, () => value)
            return newVal
        })
    }

    const debounce = useDebounce()

    const validateControl = useCallback((name: string, silent?: boolean, val?: any) => {
        const value = val || _.get(formData, name)
        const error: FormDataError = { [name]: null }
        /** Required */
        if (
            props.required?.includes(name) 
            && (value === null || value === undefined || value === '')
        ) {
            error[name] = requiredErrorMessage
        }
        /** Custom validation */
        const _error = props.validate?.(name, value, formData)
        if (_error) {
            error[name] = _error
        }

        /** Alpha validation */
        if (props.alpha?.includes(name) && value && evalAlphaValidation(name, value)) {
            error[name] = evalAlphaValidation(name, value)
        }

        /** Alpha numeric validation */
        if (props.alphaNumeric?.includes(name) && value && evalAlphaNumericValidation(name, value)) {
            error[name] = evalAlphaNumericValidation(name, value)
        }

        if (!silent) {
            setErrors((v) => ({ ...v, ...error }))
        }

        return error
    }, [formData, props])

    const getInputProps = useFormDataInputProps({ 
        formData, 
        validateOnChange: props.validateOnChange,
        validateOnBlur: props.validateOnBlur,
        validateOnKeyUp: props.validateOnKeyUp,
        getError: formDataError.getError,
        isEditing,
        toggleEditing,
        validateControl,
        onChange: handleInputChange,
        partialEdit: props.partialEdit
    })

    const evalErrors = useCallback((silent?: boolean) => {
        let _errors: FormDataError = {}

        if (!formData) {
            return {}
        }

        Object.keys(formData).forEach((key) => {
            _errors = {
                ..._errors,
                ...validateControl(key, silent)
            }
        });

        props.required?.forEach((key) => {
            _errors = {
                ..._errors,
                ...validateControl(key, silent)
            }
        })

        return _.omitBy(_errors, _.isNil)
    }, [formData, validateControl])


    const isDirty = useMemo(() => {
        return !_.isEqual(props.formData, formData)
    }, [props.formData, formData])

    return {
        formData,
        setFormData,
        handleInputChange,
        isValid,
        errors,
        resetFormData,
        resetErrors,
        isEditing, 
        toggleEditing,
        isDirty,
        getInputProps,
        validateControl,
        ...formDataError,
    }
}

const requiredErrorMessage = 'Ce champ est requis'

type Props<TData = any> = {
    id?: string

    formData: TData,
    partialEdit?: boolean

    readonly?: boolean
    /**
     * Array that contain all required fields
     */
    required?: Array<string>
    /**
     * Array that contain all required fields
     */
    alpha?: Array<string>
    /**
     * Array that contain all required fields
     */
    alphaNumeric?: Array<string>

    validateOnChange?: boolean

    validateOnBlur?: boolean
    
    validateOnKeyUp?: boolean
    /**
     * Function that check if the entiere form is valid
     * @param name Field name
     * @param value field value
     * @param formData all form data
     * @returns The new error message
     */
    validate?: (name: string, value: any, formData?: TData) => string | React.ReactNode | undefined | void

    defaultEditedFields?: Array<string>
}

export type FormDataError = Record<string, string | ReactNode>
