import { createRef, useEffect } from "react"

export const useInputAutoFocus = <TInput extends HTMLElement = HTMLInputElement>(autoFocus?: boolean) => {

    const inputRef = createRef<TInput>()

    useEffect(() => {
        autoFocus && inputRef.current?.focus()
    }, [])

    return inputRef
}
