import { AppRouteProps } from '@vadato/component'
import React from 'react'

const Donation = React.lazy(() => import('./containers/Donation'))

export const DonationsRoutes: Array<AppRouteProps> = [
    { 
        path: 'donations', 
        element: <Donation />,
    }
]
