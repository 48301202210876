import { useAjax } from "@hooks/useAjax"
import { useCallback } from "react"

export const useSaveLogs = () => {
    const ajax = useAjax()

    return useCallback((props: Logs, headers?: Record<string, string>) => {
        try {
            return ajax.post("/backoffice/v1/save/log", props, {
                headers
            })
        } catch (e) {
            console.log('Log Failed ', e)
            return;
        }
    }, [])
}

export type Logs = {
    event_name: string
    metadata?: LogsMetadata
}

export type LogsMetadata = {
    thematic: "associations" | "donators" | "donations" | 'auth';
    author?: string;
    ip_address?: string;
    thematicId?: string;
    thematicName?: string
  };