import { Dropdown } from "@components/dropdown"
import { useSuggestionsQuery } from "@hooks/data"
import _ from "lodash"
import React, { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from "react"
import { BeatLoader } from "react-spinners"
import { SuggestionList, TSuggestions } from ".."
import { BaseControl, BaseControlProps } from "./BaseControl"
import { useInputAutoFocus } from "./hooks/useInputAutoFocus"
import './css/forms'

export const Input = ({ type, readonly, endIcon, maxLength, suggestionAPI, suggestions, ...props }: InputProps) => {
    const [isFocus, setIsFocus] = useState<boolean>(false)
    const inputRef = useInputAutoFocus()
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | undefined>()
    const { data, isSuccess, isLoading } = useSuggestionsQuery(suggestionAPI, suggestionAPI !== undefined && isFocus)

    const defaultSuggestions = useMemo(() => {
        return suggestions ? suggestions : (isSuccess ? data?.map((suggestion: string) => ({ label: suggestion, value: suggestion })) : [])
    }, [isSuccess, suggestions])

    const suggestionsFilter = useMemo(() => {
        if (props.value === "" || !props.value) {
            return defaultSuggestions
        }
        return defaultSuggestions?.filter((suggestion: TSuggestions) => suggestion.value.toLowerCase().includes(props.value!.toLowerCase()))
    }, [props.value, defaultSuggestions])


    return (
        <BaseControl {...props}>
            <div className="form-group">
                <input
                    {...props}
                    value={props.value}
                    type={type || "text"}
                    data-testid={`control-${props.name}`}
                    className={`form-control ${props.inputClassName}`}
                    maxLength={maxLength}
                    onClick={(e) => {
                        setAnchorEl(e.target as any)
                    }}
                    onChange={(e) => {
                        props.onChange?.(e.target.value, e)
                        e.preventDefault()
                        e.stopPropagation()
                        if(!props.value) {
                            setIsFocus(false)
                        }
                        setIsFocus(true)
                    }}
                    onBlur={props?.suggestionOnFocus ? undefined : props.onBlur}
                    ref={inputRef}
                />
                {
                    endIcon && (typeof endIcon === 'string' ? <i className={endIcon}></i> : endIcon)
                }
            </div>
            {
                !props?.suggestionOnFocus && (suggestionAPI || suggestions) && !!props.value &&
                <Dropdown 
                    placement="bottom-start" 
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(undefined)
                        props.onBlur?.({} as any)
                    }}>
                    {isLoading ? <div className="flex justify-center items-center bg-white rounded-[10px] overflow-hidden p-[15px]"><BeatLoader /></div> : (
                        <SuggestionList
                            suggestions={suggestionsFilter}
                            onClose={() => setAnchorEl(undefined)}
                            onClick={(suggestion) => {
                                props.onChange?.(suggestion)
                                setAnchorEl(undefined)
                            }}
                            value={props.value}
                        />
                    )}
                </Dropdown>
            }
            {
                props?.suggestionOnFocus && (
                    <Dropdown 
                        placement="bottom-start" 
                        anchorEl={anchorEl}
                        onClose={() => {
                            setAnchorEl(undefined)
                            props.onBlur?.({} as any)
                        }}>
                    {isLoading ? <div className="flex justify-center items-center bg-white rounded-[10px] overflow-hidden p-[15px]"><BeatLoader /></div> : (
                        <SuggestionList
                            suggestions={suggestionsFilter}
                            onClose={() => setAnchorEl(undefined)}
                            onClick={(suggestion) => {
                                props.onChange?.(suggestion)
                                setAnchorEl(undefined)
                            }}
                            value={props.value}
                        />
                    )}
                </Dropdown>
                )
            }
        </BaseControl>
    )
}

export type InputProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    endIcon?: string | ReactNode
    type?: string
    value?: string
    autoFocus?: boolean
    maxLength?: number
    suggestAPI?: string
    onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
    suggestionAPI?: string
    suggestions?: Array<TSuggestions>
    suggestionOnFocus?: boolean
    inputClassName?: string
    "data-cy"?: string
}
