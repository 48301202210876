import React, { useState, useEffect } from "react"
import Cropper, { CropperProps } from "react-easy-crop"
import { useTranslations } from "hooks"
import { Area } from "react-easy-crop/types"
import { Dialog, DialogProps } from "@components/modal"
import { useGetCroppedImage } from "../hooks/useGetCroppedImage"
import { IconButton } from "@components/button"
import Slider from 'react-input-slider';

export const ImageCropperDialog = ({ value: _value, defaultValue, onChange, cropSize, ...props }: ImageCropperProps) => {

    const [
        ZOOM,
        ROTATE,
        SAVE,
        CANCEL
    ] = useTranslations(i18n)

    const [value, setValue] = useState<string | undefined>(_value || defaultValue)

    useEffect(() => {
        if (_value !== value) {
            setValue(_value)
        }
    }, [_value])

    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(0.5)

    const cropImage = useGetCroppedImage()

    const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleCropSave = async () => {
        try {
            const imageCropped = await cropImage(value, croppedAreaPixels, rotation)
            if (imageCropped) {
                onChange?.(imageCropped)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Dialog 
            {...props}
            onConfirm={handleCropSave}
            btnOk={{
                label: SAVE,
            }}
            btnCancel={{
                label: CANCEL,
            }}
            data-cy={`${props["data-cy"]}-cropper`}
        >
            <div className="flex flex-col gap-4">

                <div className="relative h-60">
                    <Cropper
                        image={value}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={(cropSize?.width || 1) / (cropSize?.height || 1)}
                        showGrid={false}
                        restrictPosition={false}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={handleCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>

                <div className="flex gap-4">
                    <label>{ZOOM}</label>

                    <Slider
                        x={zoom}
                        axis="x"
                        xmin={0.5}
                        xmax={3}
                        xstep={0.1}
                        onChange={({ x }) => setZoom(x as number)}
                    />
                </div>

                <div>
                    <label>{ROTATE}</label>

                    <div className="flex gap-2">
                        <IconButton
                            variant="contained"
                            icon={<img src="/assets/img/icons/rotate-left-solid.svg" className="h-6" />}
                            onClick={() => setRotation(rotation - 90)}
                        />
                        <IconButton
                            variant="contained"
                            icon={<img src="/assets/img/icons/rotate-right-solid.svg" className="h-6" />}
                            onClick={() => setRotation(rotation + 90)}
                        />
                    </div>
                </div>

            </div>
        </Dialog>
    )
}

ImageCropperDialog.defaultProps = {
    cropShape: ''
}

const i18n = [
    'Generic.PictureCropper.Zoom.label',
    'Generic.PictureCropper.Rotate.label',
    'Vadato.Dialog.Save.Label',
    'Vadato.Dialog.Cancel.Label',
]

export type ImageCropperProps = DialogProps & Partial<CropperProps> & {
    value?: string
    defaultValue?: string
    onChange?: (value?: string) => void
    "data-cy"?: string
}

