import { AlertList } from "@components/alert/AlertList"
import { useCheckUserLoggedIn } from "@hooks/runtime-env/useCheckUserLoggedIn"
import { useTranslationLang, useTranslationRoute } from "@hooks/translation"
import { useUrlParamsEncode, useUrlParamsValue } from "@hooks/url"
import { useRouterMetadataSync } from "@hooks/url/useRouterMetadataSync"
import { useRouterRoutePrivate } from "@hooks/url/useRouterRoutePrivate"
import moment from "moment"
import React, { useCallback, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AppRouteProps, AppRoutes } from "./AppRoutes"

export const AppShell = (props: AppShellProps) => {

    const hasToken = false
    useTranslationLang()
    const isUserLoggedIn = useCheckUserLoggedIn(hasToken, hasToken)
    const location = useLocation()
    useRouterMetadataSync()
    const checkRouteProtected = useRouterRoutePrivate()
    const urlEncode = useUrlParamsEncode()
    const { _t } = useUrlParamsValue()
    const url = useTranslationRoute()
    const navigate = useNavigate()

    useEffect(() => {
        console.log('init', location.pathname)
        if (location.pathname === '/' && props.initialPath) {
            navigate(url(props.initialPath))
        }
    }, [])

    useEffect(() => {
        if (
            !isUserLoggedIn
            && !_t
            && props.loginPath
            && isProtectedRoute(location.pathname)
        ) {
            window.open(`${props.loginPath}?${urlEncode({ r: window.location.href })}`, '_self')
        }
    }, [location, isUserLoggedIn, _t])

    const isProtectedRoute = useCallback((path: string) => {
        if(props.unloggedRoutes?.includes(path)){
            return false;
        }
        if (props.protectedRoutes === '*' && location.pathname !== props.loginPath) {
            return true
        }

        return checkRouteProtected(path)
    }, [])

    // if (!hasToken) {
    //     return <></>
    // }

    return (
        <>
            <AppRoutes routes={props.routes} />
            <AlertList />
        </>
    )
}

export type AppShellProps = {
    routes: Array<AppRouteProps>
    /** 
     * Routes that authorized when user is logged 
     */
    protectedRoutes?: Array<string> | '*',
    /** Initial routes */
    initialPath?: string
    /** Path where redirect if current route is unAuthorized */
    loginPath?: string
    unloggedRoutes?: Array<string>
}

