import React from "react";
import { ChromePicker, ColorResult } from "react-color";
import { Popover } from "../popover";

export const ColorPicker = (props: ColorPickerProps) => {
	const handleOnChange = (color: ColorResult) => {
		props.onChange?.(color.hex)
	}
	return (
		<div className="flex items-center gap-3">
			<Popover
				placement="top-start"
				triggerContent={
					<div
						className="h-8 w-8 rounded-full bg-black border-[1px] border-black"
						style={{ backgroundColor: props.value, border: props.border ? "1px solid black" : "" }}
					></div>
				}
			>
				<ChromePicker color={props.value} onChange={handleOnChange} />
			</Popover>
            <span>{props.label}</span>
		</div>
	);
};

export type ColorPickerProps = {
    label?: string
	value?: string;
	name?: string
	onChange?: (value: string) => void;
    border?: boolean
};
