import { useCallback, useEffect, useState } from "react";

export const useGoogleMapsInit = () => {

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        loadGoogleMaps()
    }, [])

    const loadScript = useCallback(({
        id,
        position,
        src,
        callback
    }: LoadScriptParams) => {
        if (!position) {
            return;
        }

        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('id', id);
        script.src = src;
        script.onload = () => callback?.()
        position.appendChild(script);
    }, [])

    const loadGoogleMaps = useCallback(() => {
        if (!document.querySelector('#google-maps')) {
            loadScript({
                src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_AUTOCOMPLETE_API_KEY}&libraries=places&language=fr&region=fr`,
                id: 'google-maps',
                position: document.querySelector('head'),
                callback: () => setLoaded(true)
            });
        }
    }, [])

    return true
}

type LoadScriptParams = {
    src: string,
    position: HTMLElement | null,
    id: string
    callback?: () => void
}
