import _ from "lodash"
import { useCallback } from "react"
import { useTranslations } from ".."
import { FormDataError } from "./useFormData"

export const useFormDataAlphaNumericValidation = () => {

    const [
        INVALID_MESSAGE
    ] = useTranslations(i18n)

    return useCallback((name: string, value?: string): string | undefined => {
        if (typeof value !== 'string') {
            console.warn('Try to validate alpha on non string value')
            return undefined;
        }

        if (!/^[a-zA-ZÀ-ú0-9-' &]+$/.test(value || '')) {
            return INVALID_MESSAGE
        }
        return undefined
    }, [])
}

const i18n = [
    'Validation.AlphaNumeric.Invalid.Message',
]
