import { useGenericMutation } from "@hooks/query"
import { useRuntimeEnv } from "@hooks/runtime-env"
import { useAjax } from "@hooks/useAjax"
import { useIPAddress } from "@hooks/utils"
import { useSaveLogs } from "../logs"

export const useDonatorCreateMutation = () => {

    const ajax = useAjax()
    const getIpAdress = useIPAddress()
    const {runtimeEnv} = useRuntimeEnv()
    const saveLogs = useSaveLogs()
    return useGenericMutation<TDonatorCreateResponse, Partial<TDonatorCreate>>(async (data: Partial<TDonatorCreate>) => {
        const ip = await getIpAdress()
        const req = await ajax.post<TDonatorCreateResponse>(`donator/v1/request/payment?associationId=${data.associationId}`, {
            ...data,
            associationId: undefined,
            ip_address: ip
        })
        // await saveLogs({
        //     event_name: "create",
        //     metadata: {
        //         thematic: "donators",
        //         thematicId: req.data.donator.id,
        //         thematicName: data?.first_name,
        //         author: data?.email,
        //         ip_address: ip
        //     }
        // })
        return req.data
    })
}

export type TDonatorCreate = {
    associationId: string
    first_name: string
    last_name: string
    title: string
    address: TDonatorCreateAdress,
    dob: string
    crypto_coin: string
    nationality: string
    phone: string
    country_code: string
    wallet_address: string
    email: string
    amount: number
    place_of_birth?: string
    receive_email?: boolean
    storeAssociationId?: string | number
}

export type TDonatorCreateAdress = {
    address: string
    postal_code: string
    city: string
}

export type TDonatorCreateResponse = {
    donator: TDonatorCreateDonatorResponse,
    coinhouseData: TDonatorCreateCoinHouseResponse,
    order: {
        id: string
    }
}

export type TDonatorCreateDonatorResponse = {
    id: string
}

export type TDonatorCreateCoinHouseResponse = {
    order: {
        id: string
        orderId: string
        OrderId: string
        accountId: string
        amount: number,
        currency: string
        customerAddress: string
        customer: any,
        internalRef: string
        codeProject: string
        urlsRedirect: {
            urlRedirectSuccess: string
            urlRedirectFailed: string
            urlRedirectError: string
        },
        webhookUrl: string
        dateCreated: string
        dateLastUpdated: string
    },
    addr: {
        addressId: string
        addr: string
    },
    kyt: {
        result: string
    },
    urlPayment: string
}
