import { AlertProps } from "@components/alert";
import { useDisplayAlert } from "@hooks/alert/useDisplayAlert";
import { useCallback, useEffect } from "react";
import { QueryKey, UseMutationResult, UseQueryResult } from "react-query";
import { QueryErrorType, QueryLoadingType, QuerySuccessType } from "./useGenericQuery";

/**
 * Hooks to monitor query
 * If there's error, it will show a snackbar error on the Layout
 * If there's success, it will show a snackbar success on the layout
 * These messages are customizable usign config parameter
 */
export const useMonitorQuery = 
    <TData, TError = any>(query: UseQueryResult<TData, TError> | UseMutationResult<TData, TError>,
                    config?: UseMonitorQueryConfig<TData, TError>,
                    queryKey?: QueryKey) => {
    const addAlert = useDisplayAlert()
    
    useEffect(() => {

        if (query.isError && (config?.error_message || config?.transformErrorMessage)) {
            addAlert(getErrorAlert())
        }
    }, [query.isError, query.error])

    useEffect(() => {
        if (query.isSuccess && config?.success_message) {
            addAlert(getSuccessAlert())
        }
    }, [query.isSuccess])

    useEffect(() => {
        if (query?.isLoading && (config?.showLoadingMessage || config?.navigation_loading_monitor)) {
            addAlert(getLoadingAlert())
        }
    }, [query?.isLoading])
    
    const getErrorAlert = useCallback((): AlertProps => {
        return {
            id: JSON.stringify(query),
            text: getErrorAlertLabel(),
            dismissTimeout: 10000,
            type: 'danger'
        }
    }, [query.error, config])

    const getSuccessAlert = useCallback((): AlertProps => {
        return {
            id: JSON.stringify(query),
            text:  getSuccessAlertLabel(),
            dismissTimeout: 5000,
            type: 'success'
        }
    }, [query.error, config])

    const getLoadingAlert = useCallback((): AlertProps => ({
        text: getLoadingAlertLabel(),
        dismissTimeout: 0,
        type: 'primary',
        isDismissible: false,
    }), [])

    const getLoadingAlertLabel = useCallback(() => {
        if (config?.loadingMessage) {
            return config?.loadingMessage
        }
        return 'Generic.Loading.label'
    }, [config])
    
    const getErrorAlertLabel = useCallback(() => {
        if (config?.transformErrorMessage) {
            return config?.transformErrorMessage?.(query.error || undefined)
        }
            
        if (typeof config?.error_message === 'string') {
            return config?.error_message
        }
        
        if ((query.error as any)?.message === 'Network request failed') {
            return `Generic.Exception.ServerUnreachable`
        }
        
        return `Exception.${(query.error as any)?.response?.errors?.[0]?.message}`
    }, [config, query.error])

    const getSuccessAlertLabel = useCallback(() => {
        if (config?.transformSuccessMessage) {
            return config?.transformSuccessMessage?.(query.data)
        }

        if (typeof config?.success_message === 'string') {
            return config?.success_message
        }
        
        return 'Generic.Saved.Successfully.label'
    }, [config, query.data])
}

export type UseMonitorQueryConfig<TData, TError = any> = {
    /** 
     * flag telling if we send request
     * @deprecated use enabled instead
     */
    send_request?: boolean
    /** 
     * @deprecated Use "show_loading_message" instead
     * Flag telling to navigation to show an alert of loading when react-query is fetching data 
     */
    navigation_loading_monitor?: boolean
}
    & QueryErrorType<TError>
    & QuerySuccessType<TData>
    & QueryLoadingType<TData>
