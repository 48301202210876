import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"

export const useSuggestionsQuery = (suggestionAPI?: string, isFocus?: boolean) => {
    const ajax = useAjax()
    return useGenericQuery(["Vadata.Input.Suggestions", suggestionAPI], async () => {
        try {
            const res = await ajax.get(suggestionAPI!)
            return res.data
        } catch (error) {
            return undefined
        }
    }, {enabled: isFocus})
    // return suggestionAPI ? ["Association", "Association 1", "Association 2"] : "-- * --"
}