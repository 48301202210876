import { IconButton } from "@components/button/IconButton"
import { Dropdown } from "@components/dropdown"
import { getName } from "country-list"
import React, { createRef, HTMLAttributes, useState } from "react"
import { BaseControl, BaseControlProps } from "./BaseControl"
import { CountryList } from "./components/CountryList"

export const Nationality = ({ readonly, value, ...props }: NationalityProps) => {

    const [flagButtonRef, setFlagButtonRef] = useState<HTMLDivElement>()
    const containerRef = createRef<HTMLDivElement>()

    return (
        <BaseControl {...props}>

            <div className="flex gap-2">
                <div className="flex flex-col bg-white" onClick={() => setFlagButtonRef(containerRef.current!)}>
                    <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-up.svg" className="h-[9px]" />} />
                    <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-down.svg" className="h-[9px]" />} />
                </div>
                <div className="form-group flex-1">
                    <div data-cy={props["data-cy"]} className="flex bg-white gap-4 items-center input" ref={containerRef} onClick={() => setFlagButtonRef(containerRef.current!)}>
                        <img className="h-4" src={`https://cdn.ipregistry.co/flags/wikimedia/${value?.toLocaleLowerCase()}.png`} />
                        <span>{getName(value || 'FR')}</span>
                    </div>
                </div>
            </div>


            <Dropdown anchorEl={flagButtonRef} onClose={() => setFlagButtonRef(undefined)}>
                <CountryList
                    value={value}
                    onClick={(code) => props.onChange?.(code)}
                />
            </Dropdown>
        </BaseControl>
    )
}

Nationality.defaultProps = {
    value: 'FR'
}

export type NationalityProps = BaseControlProps & Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    value?: string
    onChange?: (value: string) => void
    "data-cy"?: string
}
