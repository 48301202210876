import React from 'react'
import { BeatLoader } from 'react-spinners'
import './css/main-logo.css'

export const MainLogo = (props: MainLogProps) => {

    return (
        <div className="main-loader">
            <div className="main-spinner">
                <img src={props.img || `/logo.PNG`} className="h-16" />
                <div className='flex justify-center'>
                    <BeatLoader />
                </div>
            </div>
        </div>
    )
}

export type MainLogProps = {
    img?: string
}
