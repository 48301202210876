import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useDonatorQuery = (id?: any, suspense?: boolean, enabled?: boolean) => {
    const ajax = useAjax()
    return useGenericQuery([`donator_query_${id}`, id] , async () => {

        const endPoint = `/v1/donator?id=${id}`
        
        try {
            const res = await ajax.get(endPoint)
            return res
        } catch (error) {
            return []
        }
    }, { suspense, enabled })
}

export type TDonatorDetails = {
    firstName?: string
    lastName?: string
    title?: string
    address?: any,
    dob?: string
    cryptoCoin?: string
    nationality?: string
    phone?: string
    countryCode?: string
    walletAddress?: string
    walletMetadata?: any
    walletConnected?: boolean
    email?: string
    amount?: string
    ipAddress?: string
}