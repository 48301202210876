import { useTranslationRoute } from "@vadato/component"
import { useLeftSidebarMenu } from "hooks/useLeftSidebarMenu"
import { useUrlActive } from "hooks/useUrlActive"
import { Link } from "react-router-dom"

export const LeftSideBar = () => {

    const url = useTranslationRoute()
    const menus = useLeftSidebarMenu()
    const isActive = useUrlActive()

    return (
        <div className="bg-primary sticky top-0 bottom-0 h-screen flex flex-col items-center gap-[70px] py-10 overflow-auto overflow-x-hidden override-scroll">
            <Link to={url('/')}>
                <img src="/assets/img/logo-inv.png" alt="Vadato" className="h-[40px]" />
            </Link>

            <div className="flex flex-col">
                {
                    menus.map((item, key) => (
                        <Link data-cy={item["data-cy"]} to={item.link} key={key} className={`hover:bg-[#E8B500] ${isActive(item.link) && 'bg-[#E8B500]'}`}>
                            <div className="flex flex-col items-center gap-4 text-white py-2 px-5">
                                <img src={item.icon} className="w-[30px]" />
                                <span>{item.label}</span>
                            </div>
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}
