import { AppRouteProps } from '@vadato/component'
import React from 'react'

const Donators = React.lazy(() => import('./containers/Donators'))

export const DonatorsRoutes: Array<AppRouteProps> = [
    { 
        path: 'donators', 
        element: <Donators />,
    }
]
