import { useGenericQuery } from "@hooks/query"
import { useUrlParamsEncode } from "@hooks/url/useUrlParamsEncode"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useDonatorsQuery = (filter?: any, suspense?: boolean) => {
    const ajax = useAjax()
    const encodeUrl = useUrlParamsEncode()
    return useGenericQuery(['donators_query', filter] , async () => {

        const order: any = {}
        if(!_.isEmpty(filter.data?.amount_inf)) { 
            order.amount_inf = filter.data?.amount_inf
        }

        if(!_.isEmpty(filter.data?.amount_sup)) { 
            order.amount_sup = filter.data?.amount_sup
        }

        if(!_.isEmpty(filter.data?.amount)) { 
            order.amount = filter.data?.amount
        }

        const _filter = {data: { ..._.omit(filter.data, ['pageSize', 'page', 'key', 'amount_inf', 'amount_sup', 'amount']), order: order} as any, sort: filter.sort, key: filter.data?.key, keyFields: 'firstName,lastName,email,phone'}
        
        const endPoint = `/v1/donators/list?${encodeUrl({filter: JSON.stringify(_filter) })}`
        
        try {
            const res = await ajax.get(endPoint)
            return res
        } catch (error) {
            return []
        }
    }, {
        suspense,
        enabled: !_.isNil(filter) && !_.isNil(filter.sort)
    })
}