import _ from "lodash"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useStripTag, useTranslation } from ".."
import { useDocumentMetadata } from "./useDocumentMetadata"
import { useDocumentUpdateAlternativeLang } from "./useDocumentUpdateAlternativeLang"
import { useRouterDeclaration } from "./useRouterDeclaration"
import { useRouterDeepNestedTitle } from "./useRouterDeepNestedTitle"
import { useRouterRoutes } from "./useRouterRoutes"

export const useRouterMetadataSync = () => {

    const routes = useRouterRoutes()
    const getRouteDeclaration = useRouterDeclaration()
    const getNestedRouteTitle = useRouterDeepNestedTitle()
    const location = useLocation()
    const { setDocumentMetadata } = useDocumentMetadata()
    const t = useTranslation()
    const addAlternativeLang = useDocumentUpdateAlternativeLang()
    const stripTag = useStripTag()

    useEffect(() => {
        const route = getRouteDeclaration(routes, location.pathname)
        if (route) {
            const nestedTitle = getNestedRouteTitle(routes, location.pathname)
            setDocumentMetadata({
                ...route,
                description: stripTag(t(route.description || '')),
                keywords: t(route.keywords || ''),
                title: nestedTitle.split('-').map((item) => t(item.trim())).join(' - ')
            })
            addAlternativeLang()
        }
    }, [location])
}
