import React from "react"
import { Button } from "@components/button"
import { useCryptoList } from "../hooks/useCryptoList"

export const CryptoList = (props: CountryListProps) => {

    const cryptos = useCryptoList()

    return (
        <div 
            className="flex flex-col gap-1 bg-white rounded-[10px] overflow-hidden">
            {
                cryptos.map((item, index) => {
                    return (
                        <Button
                            key={index}
                            className={`px-2 py-2 max-w-[320px] text-left !justify-start ${props.value === item.value ? '!bg-default' : ''}`}
                            startIcon={<img src={item.icon} className="w-8" />}
                            onClick={() => props.onClick?.(item.value!)}
                            label={item.label}
                        />
                    )
                })
            }
        </div>
    )
}

export type CountryListProps = {
    value?: string
    onClick?: (value: string) => void
}

