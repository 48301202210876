import _ from "lodash"
import { useCallback, useMemo } from "react"

export const useAssociationColorValue = (value?: string) => {
    const arrayValue = useMemo(() => {
        if (!value) {
            return ['#FFD233', '#F24E1E', '#4ECB71', '#000000', '#444444', '#FFFFFF']
        }
        return (value || '').split(',')
    }, [value])
    const [nameColor, titleColor, buttonColor, borderColor, buttonTextColor, backgroundColor] = useMemo(() => arrayValue, [arrayValue])

    const insertValue = useCallback((color: string, index: number) => {
        const newValue = _.cloneDeep(arrayValue)
        newValue.splice(index, 1, color)
        return newValue.toString()
    }, [value])

    return {
        nameColor, 
        titleColor, 
        buttonColor,
        backgroundColor, 
        borderColor, 
        buttonTextColor,
        insertValue
    }
}
