import React, { HTMLAttributes, useEffect, useMemo, useState } from 'react'
import {BaseControl, BaseControlProps} from './BaseControl'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';

export const TextEditorControl = ({value, onChange, ...props}: TextEditorControlProps) => {

    const [val, setVal] = useState<string | undefined>(value)

    useEffect(() => {
        if (val !== value) {
            setVal(value)
        }
    }, [value])

    useEffect(() => {
        onChange?.(val!)
    }, [val])

    const defaultOptions = useMemo<SunEditorReactProps['setOptions']>(() => ({
        buttonList: [
            ['bold', 'underline', 'italic', 'strike'],
            ['fontSize', 'formatBlock'],
            ['subscript', 'superscript', 'link'],
            ['fontColor'],
            ['align', 'list', 'table', 'image', 'video'],
            ['showBlocks', 'fullScreen']
        ],
        height: 'auto',
        stickyToolbar: '',
        defaultStyle: 'font-family: Century-Gothic'
        // Todo : make traduction of dialog
    }), [])
    
    return (
        <BaseControl {...props}>
            <SunEditor 
                lang="fr" 
                setOptions={{
                    ...defaultOptions,
                }}
                setContents={value || ''} 
                onChange={(value) => setVal(value)}
                readOnly={props.partialEdit && !props.partialEditing} 
                />
        </BaseControl>
    )
}

export type TextEditorControlProps = BaseControlProps & Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    value?: string
    onChange?: (value: string) => void
}