import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import { useMemo } from "react"
import { TAssociationCreate } from "./useAssociationCreateMutation"
import { useAssociationInfoQuerySlug } from "./useAssociationInfoQuerySlug"
import { useParams } from "react-router-dom"

export const useAssociationInfoQuery = (id?: string, suspense?: boolean) => {
    const ajax = useAjax()

    const { associationId } = useParams()
    const slug = useAssociationInfoQuerySlug()

    return useGenericQuery<TAssociationCreate>('association-details', async () => {
        let params = "association_slug=" + slug
        if (associationId) {
            params = `associationId=` + associationId
        }

        if (id) {
            params = `associationId=` + id
        }
        const res = await ajax.get(`donator/v1/association/info?${params}`)
        return res.data
    }, { suspense, enabled: associationId !== undefined || id !== undefined || slug !== undefined, error_message: '' })
}

