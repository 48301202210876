import moment from "moment"
import React from "react"
import { Dialog } from ".."
import './css/creation-form.css'
import { CreationCryptoAddress } from "./CreationCryptoAddress"
import { CreationDonationForm } from "./CreationDonationForm"
import { CreationDonatorForm } from "./CreationDonatorForm"
import { CreationSaveSuspense } from "./CreationSaveSuspense"
import { CreationWalletForm } from "./CreationWalletForm"
import { useCreationFormData } from "./hooks/useCreationFormData"
import { AssociationCreationPersonalizedStyle } from "./AssociationCreationPersonalizedStyle"
import { useAssociationDonationSubmit } from "./hooks/useAssociationDonationSubmit"

export const AssociationCreationForm = (props: AssociationCreationFormProps) => {

    const { formData, isValid } = useCreationFormData(undefined, undefined, props.readonly)
    const {
        submit,
        creationMutation,
        showPatiente,
        currentPayment,
        isError,
        isLoading,
        isSuccess,
        isExpired,
        paymentResult,
        showDialog,
        setShowDialog
    } = useAssociationDonationSubmit()

    const handleCreationSubmit = () => {
        if (isValid()) {
            submit(formData)
        }
    }


    if (showPatiente) {
        return (
            <div className="h-full bg-secondary">
                <div className="h-screen flex justify-center items-center">
                    <CreationSaveSuspense
                        amont={creationMutation.variables?.amount || currentPayment?.coinhouseData.order.amount}
                        cryptoCurrency={creationMutation.variables?.crypto_coin || currentPayment?.coinhouseData.order.currency}
                        isError={isError}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                        onIconClick={() => {
                            if (!isExpired && paymentResult?.message === 'wait') {
                                setShowDialog(true)
                                return;
                            }
                            window.location.reload()
                        }}
                    />
                </div>

                <Dialog
                    open={showDialog}
                    className="max-w-[720px] flex justify-center"
                    onClose={() => setShowDialog(false)}>
                    <CreationCryptoAddress
                        {...(currentPayment)!}
                        onSendClick={() => setShowDialog(false)}
                    />
                </Dialog>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 association-donation-form">
            <div className="py-7 md:px-5 lg:px-8 donation-form">
                <CreationDonatorForm />
            </div>

            <div className="py-7 md:px-5 lg:px-8 donation-form">
                <CreationWalletForm />
            </div>

            <div className="py-7 md:px-5 lg:px-8 mb-4 donation-form">
                <CreationDonationForm
                    readonly={props.readonly}
                    isSubmit={creationMutation.isLoading}
                    onSubmitClick={handleCreationSubmit}
                />
            </div>
            <AssociationCreationPersonalizedStyle />
        </div>
    )
}

export type AssociationCreationFormProps = {
    readonly?: boolean
}

