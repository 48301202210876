import { useTranslations } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import React from "react"
import { ButtonSubmit, CryptoCurrency } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"

export const CreationDonationForm = (props: CreationDonationFormProps) => {

    const [
        SELECT_CRYPTO,
        SEND,
    ] = useTranslations(i18n)
    const { md } = useBreakpoints()
    const { formData, handleInputChange, isValid, getInputProps } = useCreationFormData()

    return (
        <div className="creation-form-container flex flex-col gap-7">
            <div className="flex flex-col gap-2 m-auto w-[320px]">
                <CryptoCurrency
                    {...getInputProps('amount')}
                    value={{
                        currency: formData?.crypto_coin,
                        value: formData?.amount?.toString(),
                    }}
                    onChange={(value) => {
                        handleInputChange('crypto_coin', value?.currency)
                        handleInputChange('amount', value?.value)
                    }}
                />
            </div>
            {
                !md &&
                <div className="justify-center flex">
                    <ButtonSubmit
                        className={`btn-primary font-bold text-black ${!isValid(false) ? '!bg-default' : ''}`}
                        label={SEND}
                        disabled={!isValid(false)}
                        isSubmit={props.isSubmit}
                        // style={{ backgroundColor: hasError ? '#D9D9D9' : '' }}
                        onClick={!props.readonly ? props.onSubmitClick : undefined}
                        data-cy="home-page-donator-submit-button"
                    />
                </div>
            }
        </div>
    )
}

export type CreationDonationFormProps = {
    readonly?: boolean
    isSubmit?: boolean
    onSubmitClick?: () => void
}


const i18n = [
    'Vadato.Donators.Create.Form.SelectCrypto.Label',
    'Vadato.Donators.Create.Form.Send.Label',
]
