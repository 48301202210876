import { useMemo } from "react"

export const useCryptoList = () => {

    return useMemo(() => {
        return [
            { label: 'Bitcoin', value: 'BTC', icon: '/assets/img/icons/BTC-circle.svg' },
            { label: 'Ether', value: 'ETH', icon: '/assets/img/icons/ETH-circle.svg' },
            { label: 'XRP', value: 'XRP', icon: '/assets/img/icons/XRP-circle.svg' },
            { label: 'Litecoin', value: 'LTC', icon: '/assets/img/icons/LTC-circle.svg' },
        ]
    }, [])
}
