import { useGenericMutation } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"

export const useSendOTPMutation = () => {
    const ajax = useAjax()
    return useGenericMutation((data: TSendOTP) => ajax.post('/auth/v1/forgotpassword', data), {
        success_message: "Email envoyé",
        transformErrorMessage: (error) => {
            const errorMessage = error?.response?.data?.message

            if(errorMessage === "Already send email!") {
                return "Email déjà envoyé!"
            }

            if(errorMessage === "Required email"){
                return "Email requis!"
            }
            return ""
        },
    })
}

export type TSendOTP = {
    email?: string
}