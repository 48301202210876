import { useCallback } from "react"

export const useReadFileToBase64 = () => {

    return useCallback((file: Blob): Promise<any> => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }, [])
}
