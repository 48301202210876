import { useCallback } from "react"

export const useConvertBase64ToFile = () => {

    return useCallback((url: string, filename?: string, mimeType?: string) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename || 'file', { type: mimeType }); })
        );
    }, [])
}

