import { useMemo } from "react"

export const useAssociationInfoQuerySlug = () => {

    return useMemo(() => {
        const matches = window.origin.match(/https:\/\/(.*).vadato.io/) || []
        let slug = matches[1]

        return slug
    }, [])
}
