import { AppRouteProps } from "@vadato/component";
import React from "react";

const AssociationCreate = React.lazy(() => import('./containers/AssociationCreate'))
export const AssociationCreateRoutes:Array<AppRouteProps> = [
    {
        path: "associations/create",
        element: <AssociationCreate key={"create"} />
    },
    {
        path: "associations/edit/:associationId",
        element:<AssociationCreate key={"edit"} />
    }
]