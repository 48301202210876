import { Button, ButtonProps, ButtonSubmit, ButtonSubmitProps } from "@components/button"
import { useTranslation } from "@hooks/translation"
import React from "react"

export const DialogFooter = (props: DialogFooterProps) => {
    const t = useTranslation()

    return (
        <div className="dialog-footer flex justify-center gap-7 mt-7">
            
            {
                props.btnOk &&
                <ButtonSubmit
                    label={t('std_ok')}
                    {...props.btnOk}
                    className={`btn-primary ${props.btnOk?.className}`}
                    onClick={props.onConfirm} 
                    data-cy={`${props["data-cy"]}-dialog-ok-button`} />
            }
            {
                props.btnCancel &&
                <Button
                    label={t('std_cancel')}
                    {...props.btnCancel}
                    className={`btn-primary ${props.btnCancel?.className}`}
                    onClick={props.onCancel}
                    data-cy={`${props["data-cy"]}-dialog-cancel-button`} />
            }
        </div>
    )
}

export type DialogFooterProps = {
    btnOk?: ButtonSubmitProps
    btnCancel?: ButtonProps
    onConfirm?: () => void
    onCancel?: () => void
    "data-cy"?: string
}
