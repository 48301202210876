import React from 'react'

export const GenericSuspenseError = ({ element, message, retry, className, onRetryClick }: GenericSuspenseErrorProps) => {

    return (
        <div className={`generic-suspense w-screen h-screen flex justify-center items-center ${className}`}>

            {
                !element &&
                <div className='flex flex-col justify-center gap-4'>
                    <p className="font-bold" data-testid="generic-suspense-message">
                        {message || 'Error happens while displaing the page'}
                    </p>
                    <div className='flex justify-center'>
                        <button className="btn btn-primary" onClick={onRetryClick} data-testid="generic-suspense-error-retry-btn">
                            {retry || 'Retry'}
                        </button>
                    </div>
                </div>
            }

            {
                element
            }
        </div>
    )
}

export type GenericSuspenseErrorProps = {
    className?: string
    message?: string
    retry?: string
    element?: React.ReactElement
    onRetryClick?: () => void
}
