import { useTranslations } from "@hooks/translation";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogProps } from "./Dialog"
import { useBlockNavigation } from "./hooks/useBlockNavigation";

export const DirtyDialog = ({ isDirty, ...props }: DirtyDialogProps) => {

    const [
        CANCEL_LABEL,
        CONFIRM_LABEL
    ] = useTranslations(i18n)
    const [showDialog, setShowDialog] = useState(false)
    const [lastPath, setLastPath] = useState<string>();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)
    const navigate = useNavigate()

    const handleNavigationBlocked = useCallback((nextLocation: any) => {
        if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
            setShowDialog(true);
            setLastPath(nextLocation.location.pathname);
            return false;
        }
        return true;
    }, [setLastPath])

    const handleConfirm = useCallback(() => {
        setConfirmedNavigation(true)
    }, [lastPath])

    useEffect(() => {
        if (confirmedNavigation && lastPath) {
          navigate(lastPath);
        }
      }, [confirmedNavigation, lastPath]);

    useBlockNavigation(handleNavigationBlocked, !confirmedNavigation && isDirty)

    return (
        <Dialog
            {...props}
            onClose={() => setShowDialog(false)}
            onCancel={() => setShowDialog(false)}
            onConfirm={handleConfirm}
            open={showDialog}
            btnOk={{
                label: CONFIRM_LABEL
            }}
            btnCancel={{
                label: CANCEL_LABEL
            }}
        />
    )
}

const i18n = [
    "Vadato.Dialog.Cancel.Label",
    "Vadato.Dialog.Confirm.Label",
]

export type DirtyDialogProps = Omit<DialogProps, 'open'> & {
    isDirty?: boolean
}

