import { ReactNode } from "react";
import { atom, atomFamily, RecoilState } from "recoil";

export const FormDataState = atomFamily<Record<string, any>, string>({
    key: 'state.FormDataState',
    default: {},
});

export const FormDataErrorState = atomFamily<FormDataErrorProps, string>({
    key: 'state.FormDataErrorState',
    default: {},
});

export const FormDataPartialEditState = atomFamily<Array<string>, string>({
    key: 'state.FormDataPartialEditState',
    default: [],
});

export type FormDataErrorProps = Record<string, string | ReactNode>
