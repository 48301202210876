import { useTranslations } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import { useMetaMask } from "metamask-react/lib/use-metamask"
import React, { useEffect } from "react"
import { Input, useAssociationColorValue, useAssociationInformationState } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"
import { MetamaskButton } from "./MetamaskButton"
import { useParams } from "react-router-dom"

export const CreationWalletForm = (props: Props) => {

    const [
        WALLET_LABEL,
        WALLET_BUTTON,
        WALLET_OR,
        WALLET_PUBLICKEY,
    ] = useTranslations(i18n)
    const { md } = useBreakpoints()
    const { formData, getInputProps, validateControl } = useCreationFormData()
    const { status} = useMetaMask();
	const { associationId } = useParams()
    const [associationInfo] = useAssociationInformationState(associationId as string)
    const {
        titleColor
    } = useAssociationColorValue(associationInfo.color_code)

    useEffect(() => {
        if (formData.wallet_address) {
            validateControl('wallet_address')
        }
    }, [formData.wallet_address])

    return (
        <div className="creation-form-container flex flex-col gap-5">

            <div className={`
                flex gap-4 items-center ${ !md ? 'flex-row' : 'flex-col' }
            `}>
                <p style={{ color: titleColor }}>{WALLET_LABEL}</p>
                <MetamaskButton
                    {...getInputProps('wallet_address')}
                />
            </div>
            {
                status !== 'connected' &&
                <div className={`flex ${ !md ? 'flex-row' : 'flex-col' } gap-4 items-center`}>
                    <p style={{ color: titleColor }}>{WALLET_OR}</p>
                    <p style={{ color: titleColor }}>{WALLET_PUBLICKEY}</p>
                    <Input
                        className="w-full md:w-auto"
                        {...getInputProps('wallet_address')}
                    />
                </div>
            }
        </div>
    )
}

type Props = {
    
}

const i18n = [
    'Vadato.Donators.Create.Form.Connect.Wallet.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Button.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Or.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.PublicKey.Label',
]

