import _ from 'lodash'
import moment from 'moment'
import React, { useCallback, HTMLAttributes, useEffect, useMemo } from 'react'
import { BaseControl, BaseControlProps } from './BaseControl'
import { Input, InputProps } from "./Input"

export const DateControl = ({value, onChange, readonly, ...props}: DateControlProps) => {

    const getMoment = useCallback((value: string) => {
        return moment(value).isValid() ? moment(value) : moment()
    }, [])

    const day = useMemo(() => {
        return getMoment(value!).date()
    }, [value])

    const month = useMemo(() => {
        return getMoment(value!).month()
    }, [value])

    const year = useMemo(() => {
        return getMoment(value!).year()
    }, [value])

    const days = useMemo(() => {
        const data = []
        let limit = 31

        for (var i = 1; i <= limit; i++) {
            data.push({ label: i.toString().padStart(2), value: i.toString() })
        }
        return data
    }, [])

    const months = useMemo(() => {
        const data = []
        for (var i = 0; i < 12; i++) {
            data.push({ label: _.upperFirst(moment.months(i)), value: i.toString() })
        }
        return data
    }, [])

    const years = useMemo(() => {
        const data = []
        const now = new Date().getFullYear()

        const min = props.minYear || 0
        const max = now + 1

        for (var i = min; i < max; i++) {
            data.push({ label: i.toString().padStart(2), value: i.toString().padStart(4) })
        }
        return data
    }, [])

    const parseToString = useCallback((value: moment.Moment) => {
        return value.format("YYYY-MM-DD")
    }, [])

    const changeFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {maxLength, name, value} = e.target
        const [fieldName, fieldIndex] = name.split("_")

        if(value.length >= maxLength) {
			const fieldIndex_ = parseInt(fieldIndex)

			if(fieldIndex_ < 7) {
				const nextInput = document.querySelector(`input[name=${fieldName}_${fieldIndex_+1}]`) as HTMLInputElement

				if(nextInput) {
					nextInput.focus()
				}
			}
		}
    }

    const handleDayChange = (val: string, event:React.ChangeEvent<HTMLInputElement>) => {
        const _val = getMoment(value!)
        const data = parseInt(val)
        _val.set('date', isNaN(data) ? 0 : data)
        onChange?.(parseToString(_val))
        changeFocus(event)
    }

    const handleMonthChange = (val: string, event:React.ChangeEvent<HTMLInputElement>) => {
        const _val = getMoment(value!)
        _val.set('month', parseInt(val))
        onChange?.(parseToString(_val))
        changeFocus(event)
    }

    const handleYearChange = (val: string, event:React.ChangeEvent<HTMLInputElement>) => {
        const _val = getMoment(value!)
        _val.set('year', parseInt(val))
        onChange?.(parseToString(_val))
        changeFocus(event)
    }

    return (
        <BaseControl {...props}>
            <div className='date-control justify-center'>
                <div className='flex-[0.2]'>
                    <Input value={moment(value!).isValid() ? day.toString() : "1"} name="date_1" maxLength={2} onChange={(value, event) => handleDayChange(value, event!)} suggestions={days}/>
                </div>
                <span>/</span>
                <div className='flex-[0.2]'>
                    <Input value={moment(value!).isValid() ? month.toString() : "1"} name="date_2" maxLength={2} onChange={(value, event) => handleMonthChange(value, event!)} suggestions={months} />
                </div>
                <span>/</span>
                <div className='flex-[0.4]'>
                    <Input value={moment(value!).isValid() ? year.toString() : "1"} name="date_3" maxLength={4} onChange={(value, event) => handleYearChange(value, event!)} suggestions={years} />
                </div>
                
            </div>
        </BaseControl>
    )
}

DateControl.defaultProps = {
    minYear: 1960,
}

export type DateControlProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    value?: string
    minYear?: number
    onChange?: (value: string) => void
}