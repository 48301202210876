import React from "react"
import { IconButton } from ".."

export const BaseControlLabel = (props: BaseControlLabelProps) => {

    return (
        <label htmlFor={props.id} className="flex flex-col gap-1 items-start">
            <span>
                {props.label}
                {
                    props.optionalLabel &&
                    <span className="italic font-light ml-1">{props.optionalLabel}</span>
                }
                {
                    props.required &&
                    <span className="text-danger"> *</span>
                }

                {
                    props.partialEdit &&
                    <span>
                        <IconButton
                            icon={
                                <img
                                    src={`/assets/img/icons/${!props.partialEditing ? 'pen.svg' : 'check.svg'}`}
                                    className="h-4"
                                />
                            }
                            onClick={() => props.onPartialEditClick?.()}
                        />
                    </span>
                }
            </span>
            {
                props.subtitle &&
                <span className="italic font-light text-sm">{props.subtitle}</span>
            }

        </label>
    )
}

export type BaseControlLabelProps = {
    /** Id of the control */
    id?: string,
    /** Name of the control. Used when control is about be serialized */
    name?: string,
    /** Label of the control */
    label?: string,

    placeholder?: string
    required?: boolean
    /** Optional label of the control */
    optionalLabel?: string
    /** Subtitle label of the control */
    subtitle?: string
    /** 
     * 
     */
    partialEdit?: boolean
    partialEditing?: boolean
    onPartialEditClick?: () => void
}
