import React, { createRef, HTMLAttributes, useState } from 'react'
import { Dropdown, DropdownProps } from '../dropdown'
import './css/style.css'

export const Popover = (props: PopoverProps) => {
    const containerRef = createRef<HTMLDivElement>()
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setAnchorEl(e.currentTarget)
    }

    const handleOnClose = () => {
        setAnchorEl(undefined)
    }
    return (
        <>
            <div
                onClick={handleClick}
                ref={containerRef}
                className={props.className}
            >
                {props.triggerContent}
            </div>
            <Dropdown placement={props.placement} anchorEl={anchorEl} onClose={handleOnClose} arrow>
                <div className='popover-container'>
                    {props.children}
                </div>
            </Dropdown>
        </>
    )
}

export type PopoverProps = HTMLAttributes<HTMLDivElement> & {
    triggerContent?: React.ReactNode
    placement?: DropdownProps['placement']
}