import React from "react"
import { useAssociationColorValue } from "./hooks/useAssociationColorValue"
import { useAssociationInformationState } from "./hooks/useAssociationInformationState"

export const AssociationCreationPersonalizedStyle = () => {

    const [associationInfo] = useAssociationInformationState()
    const {
        backgroundColor,
        borderColor,
        buttonColor,
        buttonTextColor,
        nameColor,
        titleColor
    } = useAssociationColorValue(associationInfo.color_code)

    return (
        <style>
            {
                `
                .association-donation-form .control .form-group {
                    border: 1px solid ${borderColor};
                    overflow: hidden;
                }
                .association-donation-form .control label,
                .association-donation-form .control .checkbox-item {
                    color: ${titleColor};
                }
                .association-donation-form .btn {
                    background-color: ${buttonColor};
                    color: ${buttonTextColor};
                }
                `
            }
        </style>
    )
}
