import { TDonatorCreateResponse } from "@hooks/data"
import { useGenericMutation } from "@hooks/query"
import { useMetaMask } from "metamask-react/lib/use-metamask"
import { useCallback } from "react"
import { utils } from 'web3'

export const useMetamaskMutation = () => {

    const getAmontHex = useCallback((amount: number) => {
        return (amount * Math.pow(10, 18)).toString()
    }, [])
    
    const { ethereum } = useMetaMask();
    
    return useGenericMutation((data: TDonatorCreateResponse) => {
        const value = utils.toWei(data.coinhouseData.order.amount.toString(), 'ether')

        return ethereum.request({
            method: 'eth_sendTransaction',
            params: [{
                // gas: '21000', // customizable by user during MetaMask confirmation.
                to: data.coinhouseData.addr.addr, // Required except during contract publications.
                from: ethereum.selectedAddress, // must match user's active address.
                value: parseFloat(value).toString(16), // Only required to send ether to the recipient from the initiating external account.
            }],
        });
    })
}
