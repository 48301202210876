import { useSetRecoilState } from "recoil"
import { AlertListState } from "@state/AlertListState"
import { AlertProps } from "../../components/alert/Alert"
import { useCallback } from "react"
import { v4 as uuid } from "uuid";

export const useDisplayAlert = () => {

    const setAlertList = useSetRecoilState(AlertListState)

    const addAlert = useCallback((alert: AlertProps) : string => {
        const id = uuid()
        setAlertList((l) => {
            const list  = [...l]
            const index = list.findIndex((item) => item.id === alert.id)
            if (index !== -1) {
                list.splice(index, 1, alert)
            } else {
                list.push({ id, ...alert })
            }
            return list
        })
        return id
    }, [])

    return addAlert
}
