import { useCallback, useMemo } from "react"
import { useCryptoCurrency } from "./useCryptoCurrency"

export const useCryptoConvertion = () => {
    
    const {data} = useCryptoCurrency(true)
    
    const convertToEuro = useCallback((amont: number, currency: string) => {
        const currencyAmont = data?.[currency] || 0

        return (amont || 0) * currencyAmont
    }, [data])
    
    const convertToCrypto = useCallback((amont: number, currency: string) => {
        const currencyAmont = data?.[currency] || 0

        return (amont || 0) / currencyAmont
    }, [data])

    return {
        convertToEuro,
        convertToCrypto
    }

}

