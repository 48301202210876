import { useGenericQuery } from "@hooks/query"
import { useUrlParamsEncode } from "@hooks/url/useUrlParamsEncode"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useDonationsQuery = (filter?: any, suspense?: boolean) => {
    const ajax = useAjax()
    const encodeUrl = useUrlParamsEncode()
    return useGenericQuery(['donations_query', filter] , async () => {
        const donator: any = {}
        if(!_.isEmpty(filter.data?.cryptoCoin)) { 
            donator.cryptoCoin = filter.data?.cryptoCoin
        }
        const _filter : any = {data: { ..._.omit(filter.data, ['pageSize', 'page', 'key', 'cryptoCoin']), donator: donator }, sort: filter.sort, key: filter.data?.key, keyFields: 'refId,association.firstName,donator.firstName,donator.lastName,donator.email,donator.phone,donator.walletAddress'}
        
        try {
            const res = await ajax.get(`/v1/dons/list?${encodeUrl({filter: JSON.stringify(_filter)})}`)
            return res
        } catch (error) {
            return []
        }
    }, {
        suspense, 
        enabled: !_.isNil(filter) && !_.isNil(filter.sort)
    })
}