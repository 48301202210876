import { useListFilterState } from "./useListFilterState";
import _ from "lodash";
import { TListFilterState } from "../state/ListFilterState";

export const useListFilter = <TData extends {}>(id: string, isUrlParamsState: boolean = true, defaultValue?: TListFilterState<TData>) => {
    const { filter, setFilter, resetGlobalFilterValue } = useListFilterState<TData>(id, isUrlParamsState, defaultValue)
    
    const handlePageSizeChange = (value: number) => {
        setFilter('pageSize', value)
    };

    const handlePageChange = (value: number) => {
        setFilter('page', value)
    };

    return {
        filter: {
            ...filter,
            pageSize: parseInt(filter?.pageSize?.toString() || '20'),
            page: parseInt(filter?.page?.toString() || '0'),
        },
        setFilter,
        resetGlobalFilterValue,
        handlePageSizeChange,
        handlePageChange
    }
}
