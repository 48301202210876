import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"
import { useState } from "react"

export const useDonatorOrderStatusQuery = (id?: string, interval: number = 15000) => {
    const ajax = useAjax()
    const [status, setStatus] = useState('wait')

    return useGenericQuery<DonatorOrderStatusResponse>([`Donator.Order.${id}`, id], async () => {
        try {
            const res = await ajax.get(`/donator/v1/payment/status?order_id=${id}`)
            setStatus(res.data.message)
            return res.data
        } catch (e) {
            return { message: 'error' }
        }
    }, { refetchInterval: status === 'wait' ? interval : undefined, enabled: (status === 'wait' && id !== undefined) })
}

export type DonatorOrderStatusResponse = {
    message: string
}
