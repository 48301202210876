import { AppRouteProps } from "@vadato/component";
import React from "react";
import { ForgotPasswordRoutes } from "./views/forgot-password/ForgotPasswordRoutes";
import { LogoutRoutes } from "./views/logout/LogoutRoutes";

const Auth = React.lazy(() => import('./containers/Auth'))
export const AuthRoutes: Array<AppRouteProps> = [
    {
        path: '',
        element: <Auth />,
        children: [
            {
                path: "login",
                children: [
                    ...ForgotPasswordRoutes
                ]
            },
            ...LogoutRoutes
        ]

    },
] 