import { Button } from "@components/button/Button";
import React, { useCallback, useEffect, useState } from "react";

export const SuggestionList = (props: SuggestionItemProps) => {

	const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

	const chooseSuggestion = useCallback((suggestion: any) => {
		props.onClick?.(suggestion); props?.onClose?.()
	}, [])

	// up and down navigation
	const handleKeyDown = useCallback((event: any) => {
		if (event.key === 'ArrowUp') {
			event.preventDefault();
			setSelectedOptionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : props.suggestions!.length - 1));
		} else if (event.key === 'ArrowDown') {
			event.preventDefault();
			setSelectedOptionIndex((prevIndex) => (prevIndex < props.suggestions!.length - 1 ? prevIndex + 1 : 0));
		} else if (event.key === 'Enter') {
			event.preventDefault();
			if (selectedOptionIndex >= 0 && selectedOptionIndex < props.suggestions!.length) {
				chooseSuggestion(props.suggestions?.[selectedOptionIndex].value)
			}
		}
	}, [selectedOptionIndex, props.suggestions])

  	useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
  	}, [selectedOptionIndex])

  return (
    <div className="flex flex-col gap-1 bg-white rounded-[10px] overflow-hidden">
      {props.suggestions?.map((suggestion, index) => (
          <Button
            key={index}
            label={suggestion.label}
            className={`px-3 py-2 max-w-[320px] hover:bg-[#e9e9e9] text-left !justify-start !rounded-none ${
              props.value === suggestion.value ? "!bg-default" : ""
            }`}
			style={{
				backgroundColor: index == selectedOptionIndex ? '#e9e9e9' : ''
			}}
            labelClassName='text-[15px]'
            onClick={() => {chooseSuggestion(suggestion.value)}}
          />
        ))}
    </div>
  );
};

export type SuggestionItemProps = {
  value?: string;
  suggestions?: Array<TSuggestions>;
  onClick?: (suggestion: string) => void;
  onClose?: () => void
};

export type TSuggestions = {
    label: string
    value: string
}
