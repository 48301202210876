import { useGenericQuery } from "@hooks/query/useGenericQuery";
import { useAjax } from "@hooks/useAjax";
import axios from "axios";

export const useCryptoCurrency = (suspense?: boolean) => {

    const ajax = useAjax()
    return useGenericQuery<CryptoCurrencyResponse>(['Crypto.Currency'], async () => {
        const res = await ajax.get("/v1/crypto/currency")
        return res.data
    }, { suspense })

}

type CryptoCurrencyResponse = {
    'BTC': number,
    'LTC': number,
    'ETH': number,
    'XRP': number
}
