
import { Outlet } from "react-router-dom";
import "./css/mainLayout.css";
import { GenericSuspense, useBreakpoints } from "@vadato/component";
import { LeftSideBar } from "./LeftSideBar";
import { BlockMobileMessage } from "./BlockMobileMessage";


const MainLayout = () => {

    const { md } = useBreakpoints()

    if (md) {
        return (
            <BlockMobileMessage />
        )
    }

    return (
        <div className="flex flex-row gap-10 relative">
            <LeftSideBar />
            <main className="flex-1">
                <GenericSuspense>
                    <Outlet />
                </GenericSuspense>
            </main>
        </div>
    );
};

export default MainLayout;
