import { AppRouteProps } from '@vadato/component'
import React from 'react'

const Association = React.lazy(() => import('./containers/Association'))

export const AssociationRoutes: Array<AppRouteProps> = [
    { 
        path: 'association', 
        element: <Association />,
    }
]
