import { useCallback } from "react"
import { useAjax } from ".."

export const useIPAddress = () => {

    const ajax = useAjax()
    return useCallback(async () => {
        const response = await ajax.get('https://api.ipify.org?format=json', {
            headers: {
                "Authorization": undefined
            }
        })
        return response.data?.ip
    }, [])
}
