import { useGenericMutation } from "@hooks/query"
import { useRuntimeEnv } from "@hooks/runtime-env"
import { useAjax } from "@hooks/useAjax"
import { useIPAddress } from "@hooks/utils"
import _ from "lodash"
import { useSaveLogs } from "../logs"

export const useAssociationStatusMutation = () => {
    const ajax = useAjax()
    const getIpAdress = useIPAddress()
    const {runtimeEnv} = useRuntimeEnv()
    const saveLogs = useSaveLogs()
    return useGenericMutation(async (data: TAssociationStatus) => {
        await ajax.post('backoffice/v1/association/status', {
            ...(_.omit(data, ["association_name"]))
        })
        // const ip = await getIpAdress()
        // await saveLogs({
        //     event_name: data.status,
        //     metadata: {
        //         thematic: "associations",
        //         thematicName: data.association_name,
        //         thematicId: data.association_id,
        //         author: runtimeEnv.email,
        //         ip_address: ip
        //     }
        // })
    })
}

export type TAssociationStatus = {
    association_id: string
    association_name: string
    status: 'delete' | 'archive' | 'publish' | 'unpublish'
}

