import React, { forwardRef, HTMLAttributes, useMemo } from "react"
import ReactDatePicker from "react-datepicker"
import { BaseControl, BaseControlProps } from "./BaseControl"
import moment from 'moment'
import { Input } from './Input'
import { DateRangeButtonInput } from './DateRangeButtonInput'
import { DatePicker } from "./DatePicker"

export const DateRange = ({ readonly, value, ...props }: DateRangeProps) => {

    const startDate = useMemo(() => moment(value?.[0]).toDate(), [value])
    const endDate = useMemo(() => moment(value?.[1]).toDate(), [value])

    return (
        <BaseControl {...props}>
            <div className="control-date flex gap-2 items-center">
                <DatePicker
                    selected={moment(startDate).toDate()}
                    onChange={(date) => props.onChange?.([moment(date).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<DateRangeButtonInput />}
                />

                <img src="/assets/img/icons/calendar.svg" className="h-4" />

                <DatePicker
                    selected={moment(endDate).toDate()}
                    onChange={(date) => props.onChange?.([moment(startDate).format('YYYY-MM-DD'), moment(date).format('YYYY-MM-DD')])}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    customInput={<DateRangeButtonInput />}
                />
            </div>
        </BaseControl>
    )
}

export const DateRangeInput = forwardRef(({ value, onClick }: any, ref: any) => {

    return (
        <div ref={ref} onClick={onClick}>
            <Input value={value} />
        </div>
    )
})

export type DateRangeProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    value?: [string, string]
    autoFocus?: boolean
    onChange?: (value: [string, string]) => void
}
