import { atom, RecoilState } from "recoil"

const atoms: Record<string, any> = {}

export const AssociationInformationState = (id: string, defaultValue?: any): RecoilState<any> => {
    if (!atoms[id]) {
        atoms[id] = atom<any>({
            key: `state.AssociationInformationState.${id}`,
            default: defaultValue || {}
        })
    }
    return atoms[id]
}
