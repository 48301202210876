import React, { HTMLAttributes, ReactNode, useCallback } from "react"
import { BaseControl, BaseControlProps } from "./BaseControl"
import { useInputAutoFocus } from "./hooks/useInputAutoFocus"

export const Numeric = ({ readonly, endIcon, ...props }: NumericProps) => {

    const inputRef = useInputAutoFocus()

    const triggerChange = useCallback((value: number) => {

        if(isNaN(value)) {
            props.onChange?.("")
            return;
        }

        let nextVal = parseFloat(value.toFixed(props.digits))
        if (props.positiveOnly && value < 0) {
            nextVal = 0
        }

        if (props.max && nextVal > props.max) {
            return;
            // nextVal = props.max
        }

        if (props.min && nextVal < props.min) {
            nextVal = props.min
        }
        props.onChange?.(nextVal.toString())
    }, [props])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = e.target.value
        value = value.replace(/\s|[A-Za-z]|=|;/g, "")
        triggerChange(parseFloat(value))
    }

    const increment = useCallback(() => {
        let _val = props.value || 0
        triggerChange(_val + (props.step || 1))
    }, [props])

    const decrement = useCallback(() => {
        let _val = props.value || 0
        triggerChange(_val - (props.step || 1))
    }, [props])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        //haut
        if (e.keyCode === 33 || e.keyCode === 38) {
            increment()
        }
        //bas
        if (e.keyCode === 34 || e.keyCode === 40) {
            decrement()
        }

        if (e.keyCode === 13) {
            inputRef.current?.blur()
        }
    }

    const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
        // up
        if (e.deltaY < 0) {
            increment()
        }

        // down
        if (e.deltaY > 0) {
            decrement()
        }
    }

    return (
        <BaseControl {...props} className={`control-numeric ${props.className}`}>
            <div className="form-group relative">
                <input
                    {...props}
                    type="text"
                    data-testid={`control-${props.name}`}
                    className="form-control w-full"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onWheel={handleWheel}
                    ref={inputRef}
                    autoComplete="off"
                />
                {
                    endIcon && (typeof endIcon === 'string' ? <i className={endIcon}></i> : endIcon)
                }
            </div>
        </BaseControl>
    )
}

Numeric.defaultProps = {
    step: 1,
    digits: 6
}

Numeric.properties = [
    // ...Input?.properties,
    'step',
    'positiveOnly',
    'digits',
    'min',
    'max'
]

export type NumericProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    value?: number
    autoFocus?: boolean
    step?: number
    positiveOnly?: boolean
    digits?: number
    /** Min value allowed */
    min?: number,
    /** Max value allowed */
    max?: number,
    endIcon?: string | ReactNode
    onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void
    "data-cy"?: string
}