import React from "react"
import { BaseControlError, BaseControlErrorProps } from "./BaseControlError"
import { BaseControlLabel, BaseControlLabelProps } from "./BaseControlLabel"
import { BaseControlSuccess, BaseControlSuccessProps } from "./BaseControlSuccess"
import './css/control.css'

export const BaseControl = (props: BaseControlProps) => {

    return (
        <div className={`control ${props.className || ''}`} data-testid={`control-label-${props.name}`} onClick={props.onClick}>
            {
                props.label &&
                <BaseControlLabel {...props} />
            }


            {props.children}

            {
                (props.success || props.indication) &&
                <div className="mt-[-15px]">
                    <BaseControlSuccess {...props} />
                </div>
            }

            {
                props.error &&
                <BaseControlError name={props.name} error={props.error} />
            }
        </div>
    )
}

export type BaseControlProps = BaseControlLabelProps & BaseControlErrorProps & BaseControlSuccessProps & {
    /** Id of the form that control is associated */
    form?: string
    tooltip?: string
    className?: string,
    children?: React.ReactNode
    readonly?: boolean
    onClick?: () => void
}
