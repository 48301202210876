import React, { useCallback, useMemo } from 'react'
import { Alert, AlertProps } from './Alert'
import { useRecoilState } from 'recoil'
import { AlertListState } from '@state/AlertListState'

/**
 * Render a floating alert wrapper on the screen
 */
export const AlertList = ({ alerts }: AlertListProps) => {

    const [alertList, setAlertList] = useRecoilState(AlertListState)

    const removeAlert = useCallback((index?: number) => {
        setAlertList((l) => {
            const list = [...l]
            list.splice(index!, 1)
            return list
        })
    }, [alertList])

    const _alerts = useMemo(() => {
        return [
            ...(alertList || []),
            ...(alerts || [])
        ]
    }, [alertList, alerts])

    return (
        <>
            {
                _alerts.map((item, index) => (
                    <Alert 
                        key={index}
                        positionX="center"
                        positionY='top'
                        dismissTimeout={5000}
                        {...item}
                        style={{ marginTop: 50 * index }}
                        onClose={() => removeAlert(index)}
                        />
                ))
            }
        </>
    )
}

export type AlertListProps = {
    alerts?: Array<AlertProps>
}

