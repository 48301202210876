import { useCallback } from "react"

export const useReadImageFile = () => {

    return useCallback((file: Blob): Promise<HTMLImageElement> => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const img = new Image();
                img.onload = function () {
                    resolve(img)
                };
                img.src = reader.result as string;
            }, false)
            reader.readAsDataURL(file)
        })
    }, [])
}
