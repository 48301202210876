import { useGenericMutation } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"

export const useChangePasswordMutation = () => {
    const ajax = useAjax()
    return useGenericMutation((data: TChangePassword) => ajax.post("/auth/v1/changepassword", data))
}

export type TChangePassword = {
    change_password_request_key: string
    password: string
}