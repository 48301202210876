import React, { HTMLAttributes } from "react"

export const DialogBody = (props: DialogBodyProps) => {

    return (
        <div {...props} className={`dialog-body ${props.className}`}>

        </div>
    )
}

export type DialogBodyProps = HTMLAttributes<HTMLDivElement>
