import React, { HTMLAttributes, useState } from 'react'
import { IconButton } from '..'
import { BaseControl, BaseControlProps } from './BaseControl'

export const Password = ({ readonly, canToggle, ...props }: PasswordProps) => {

    const [type, setType] = useState<string>('password')

    const handleButtonShowClick = () => {
        setType((val) => val === 'password' ? 'text' : 'password')
    }

    return (
        <BaseControl {...props}>
            {
                !readonly &&
                <div className='relative'>
                    <input
                        {...props}
                        type={type}
                        data-testid={`control-${props.name}`}
                        className="form-control w-full"
                        readOnly={readonly}
                        onChange={(e) => props.onChange?.(e.target.value)}
                    />
                    <div className="absolute top-0 right-0 bottom-0 flex flex-col justify-center">
                        {
                            canToggle &&
                            <IconButton
                                data-testid="control-password-toggle"
                                className="control-btn"
                                icon={<img src="/assets/img/icons/eye-icon.png" loading="lazy" alt="" />}
                                onClick={handleButtonShowClick}
                            />
                        }
                    </div>
                </div>
            }
        </BaseControl>
    )
}

Password.defaultProps = {
    canToggle: true
}

export type PasswordProps = BaseControlProps & HTMLAttributes<HTMLInputElement> & {
    value?: string
    /** Flag telling if control allow to show plain text */
    canToggle?: boolean
    onChange?: (value: string) => void
    "data-cy"?: string
}
