import React, { useMemo } from "react"

export const ListHeaderItem = ({ label, sort, count, onClick, sortable = true}: ListHeaderItemProps) => {

    const sortIcon = useMemo(() => {
        if(!sortable) return;
        
        if (sort === 'ASC') {
            return `fa-chevron-up`
        }
        if (sort === 'DESC') {
            return 'fa-chevron-down'
        }
        return `fa-chevron-right`
    }, [sort])

    return (
        <div className="flex-1 flex flex-col items-center justify-center" onClick={onClick}>
            <div className={`flex justify-center items-center gap-2 overflow-hidden ${sort ? 'text-primary' : 'text-default'}`}>
                <h4 className="font-bold uppercase overflow-hidden text-ellipsis">{ label }</h4>
                {
                    sortable !== false &&
                    <i className={`fa-solid ${sortIcon}`}></i>
                }
            </div>
            <div>
            {
                count &&
                <span className="text-primary italic text-[15px]">({count})</span>
            }
            </div>
        </div>
    )
}

export type ListHeaderItemProps = {
    label: string
    count?: number
    sort?: 'ASC' | 'DESC'
    value: string
    sortable?: boolean
    onClick?: () => void
}
