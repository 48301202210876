
import { atom, RecoilState } from "recoil";
import { SortValue } from "../ListHeader";

const atoms: Record<string, any> = {}

export const ListFilterState = (id: string, defaultValue?: any): RecoilState<TListFilterState<any>> => {
    if (!atoms[id]) {
        atoms[id] = atom<TListFilterState<any>>({
            key: `state.ListFilterState.${id}`,
            default: defaultValue || {}
        })
    }
    return atoms[id]
}

export type TListFilterState<TData extends {} = Record<string, any>> = TData 
& {
    sort?: Array<SortValue>
    searchKey?: string
}
