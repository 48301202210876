import _ from "lodash"
import React, { useCallback } from "react"
import { ListBody, ListBodyProps } from "./ListBody"
import { useListFilter } from "./hooks/useListFilter"
import { ListHeader, ListHeaderProps, SortEnum } from "./ListHeader"
import { TListFilterState } from "./state/ListFilterState"


export const List = ({className, ...props}: ListProps) => {

    const { filter, setFilter } = useListFilter(props.listId || _.uniqueId(), props.isUrlParamsState, props.defaultFilter)

    const handleSortBarChange = useCallback((value: string, sort: SortEnum | null) => {
        setFilter((v: TListFilterState) => {
            const newValue = v.sort?.filter((item) => item.value !== value) || []
            if (sort !== null) {
                return { ...v, sort: [...newValue, { value, sort }] }
            }
            return { ...v, sort: newValue }
        })
    }, [setFilter])

    return (
        <div className={`flex flex-col gap-4 mb-5 ${className}`}>
            <ListHeader {...props} value={filter.sort} onChange={handleSortBarChange} />
            {
                props.rows && ( props.rows.length == 0 ) ? (
                    <div className="h-[150px] flex justify-center items-center text-default text-[1.5em] font-bold">Aucun résultat</div>
                ) : (
                    <ListBody {...props} columnLength={props.columns.length} listClassName={props?.customRowClass}/>
                )
            }
        </div>
    )
}

export type ListProps = ListHeaderProps & ListBodyProps & {
    listId?: string
    isUrlParamsState?: boolean
    defaultFilter?: any
    customRowClass?: string
    className?: string
}


